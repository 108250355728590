/**
 * @generated SignedSource<<8b61fa8805f8aea46abe30f1cc2fb337>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type authLoginV2AuthSimplifiedMutation$variables = {
  address: string;
  chain?: | "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "MUMBAI" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "GOERLI" | "SOLDEV" | "ARBITRUM_GOERLI" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "OPTIMISM_GOERLI" | "SEPOLIA" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "GUNZILLA_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "ZORA" | "ZORA_TESTNET" | "ZORA_SEPOLIA" | "B3_SEPOLIA" | "%future added value" | null;
  deviceId: string;
  message: string;
  signature: string;
};
export type authLoginV2AuthSimplifiedMutation$data = {
  readonly AuthTypeV2: {
    readonly webLoginV2: {
      readonly address: string;
      readonly isEmployee: boolean;
    };
  };
};
export type authLoginV2AuthSimplifiedMutation = {
  response: authLoginV2AuthSimplifiedMutation$data;
  variables: authLoginV2AuthSimplifiedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "address"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chain"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "message"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "signature"
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthTypeV2",
    "kind": "LinkedField",
    "name": "AuthTypeV2",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "address",
            "variableName": "address"
          },
          {
            "kind": "Variable",
            "name": "chain",
            "variableName": "chain"
          },
          {
            "kind": "Variable",
            "name": "deviceId",
            "variableName": "deviceId"
          },
          {
            "kind": "Variable",
            "name": "message",
            "variableName": "message"
          },
          {
            "kind": "Variable",
            "name": "signature",
            "variableName": "signature"
          }
        ],
        "concreteType": "WebTokenAuth",
        "kind": "LinkedField",
        "name": "webLoginV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "address",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isEmployee",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "authLoginV2AuthSimplifiedMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "authLoginV2AuthSimplifiedMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "b5c8fcd5f7b8c2b1f38f43dfe87b4ddf",
    "id": null,
    "metadata": {},
    "name": "authLoginV2AuthSimplifiedMutation",
    "operationKind": "mutation",
    "text": "mutation authLoginV2AuthSimplifiedMutation(\n  $address: AddressScalar!\n  $message: String!\n  $deviceId: String!\n  $signature: String!\n  $chain: ChainScalar\n) {\n  AuthTypeV2 {\n    webLoginV2(address: $address, deviceId: $deviceId, message: $message, signature: $signature, chain: $chain) {\n      address\n      isEmployee\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "90a52376788bc464fa2f9d09f8f63fa6";

export default node;
