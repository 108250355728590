import React, { createContext, useCallback, useMemo, useState } from "react"
import { noop } from "lodash"
import { v4 as uuid } from "uuid"
import { useGetTransactionRevertReason } from "@/components/blockchain/useGetTransactionRevertReason"
import { ToastsContainer, ToastT } from "@/design-system/Toast"
import { useRevertReasonErrorString } from "@/hooks/useJsonRpcError"
import { JsonRpcError } from "@/lib/chain/provider"
import { maybeGetGraphQLResponseErrors } from "@/lib/graphql/error"
import { Promiseable } from "@/lib/helpers/promise"
import { CaptureExceptionArgs, captureNoncriticalError } from "@/lib/sentry"

const LONG_TOAST_TIMEOUT_MS = 6_000
const SHORT_TOAST_TIMEOUT_MS = 2_000

const TOAST_BOTTOM_OFFSET_DEFAULT_PADDING_MOBILE = 12
const TOAST_BOTTOM_OFFSET_DEFAULT_MOBILE =
  84 + TOAST_BOTTOM_OFFSET_DEFAULT_PADDING_MOBILE

export const TOAST_BOTTOM_OFFSET_DEFAULT_PADDING_DESKTOP = 36
const TOAST_BOTTOM_OFFSET_DEFAULT_DESKTOP =
  TOAST_BOTTOM_OFFSET_DEFAULT_PADDING_DESKTOP

export const TOAST_BOTTOM_OFFSET_DEFAULT = {
  mobile: TOAST_BOTTOM_OFFSET_DEFAULT_MOBILE,
  desktop: TOAST_BOTTOM_OFFSET_DEFAULT_DESKTOP,
}

export type ToastBottomOffsetDefault = typeof TOAST_BOTTOM_OFFSET_DEFAULT

type ShowMessageOptions = Pick<ToastT, "onClick" | "timeout">

type ToastContextType = {
  addToasts: (toasts: Omit<ToastT, "key">[]) => void
  removeToast: (key: string) => void
  showErrorMessages: (
    messages: React.ReactNode[],
    options?: ShowMessageOptions,
  ) => void
  showErrorMessage: (
    message: React.ReactNode,
    options?: ShowMessageOptions,
  ) => void
  showInfoMessage: (
    message: React.ReactNode,
    options?: ShowMessageOptions,
  ) => void
  showSuccessMessage: (
    message: React.ReactNode,
    options?: ShowMessageOptions,
  ) => void
  showWarningMessage: (
    message: React.ReactNode,
    options?: ShowMessageOptions,
  ) => void
  attempt: (
    callback: () => Promiseable<unknown>,
    options?: {
      rethrow?: boolean | undefined
      onError?: ((error: Error) => unknown) | undefined
      errorTags?: CaptureExceptionArgs["tags"]
    },
  ) => Promise<void>
  toastYOffset: ToastBottomOffsetDefault
  setToastYOffset: React.Dispatch<
    React.SetStateAction<{
      mobile: number
      desktop: number
    }>
  >
  toastXOffset: number
  setToastXOffset: (_: number) => void
}

export const ToastContext = createContext<ToastContextType>({
  addToasts: noop,
  removeToast: noop,
  showErrorMessages: noop,
  showErrorMessage: noop,
  showInfoMessage: noop,
  showSuccessMessage: noop,
  showWarningMessage: noop,
  toastYOffset: TOAST_BOTTOM_OFFSET_DEFAULT,
  setToastYOffset: () => null,
  toastXOffset: 0,
  setToastXOffset: () => null,
  attempt: () => Promise.resolve(),
})

type Props = {
  children: React.ReactNode
}

export const ToastProvider = ({ children }: Props) => {
  const [toasts, setToasts] = useState<ToastT[]>([])
  const [toastYOffset, setToastYOffset] = useState(TOAST_BOTTOM_OFFSET_DEFAULT)
  const [toastXOffset, setToastXOffset] = useState(0)

  const addToasts = useCallback(
    (toastElements: Omit<ToastT, "key">[]) =>
      setToasts(toasts => [
        ...toasts,
        ...toastElements.map(t => ({ ...t, key: uuid() })),
      ]),
    [],
  )

  const removeToast = useCallback((key: string) => {
    setToasts(toasts => toasts.filter(t => t.key !== key))
  }, [])

  const showErrorMessages = useCallback(
    (
      messages: React.ReactNode[],
      { timeout = LONG_TOAST_TIMEOUT_MS, onClick }: ShowMessageOptions = {},
    ) =>
      addToasts(
        messages.map(message => ({
          content: message,
          variant: "error",
          timeout,
          onClick,
        })),
      ),
    [addToasts],
  )

  const showErrorMessage = useCallback(
    (message: React.ReactNode, options?: ShowMessageOptions) =>
      showErrorMessages([message], options),
    [showErrorMessages],
  )

  const showSuccessMessage = useCallback(
    (
      message: React.ReactNode,
      { timeout = LONG_TOAST_TIMEOUT_MS, onClick }: ShowMessageOptions = {},
    ) =>
      addToasts([
        {
          content: message,
          variant: "success",
          timeout,
          onClick,
        },
      ]),
    [addToasts],
  )

  const showWarningMessage = useCallback(
    (
      message: React.ReactNode,
      { timeout = LONG_TOAST_TIMEOUT_MS, onClick }: ShowMessageOptions = {},
    ) =>
      addToasts([
        {
          content: message,
          variant: "warning",
          timeout,
          onClick,
        },
      ]),
    [addToasts],
  )

  const showInfoMessage = useCallback(
    (
      message: React.ReactNode,
      { timeout = SHORT_TOAST_TIMEOUT_MS, onClick }: ShowMessageOptions = {},
    ) =>
      addToasts([
        {
          content: message,
          variant: "info",
          timeout,
          onClick,
        },
      ]),
    [addToasts],
  )

  const { getTransactionRevertReason } = useGetTransactionRevertReason()
  const { getErrorString } = useRevertReasonErrorString()
  const attempt = useCallback(
    async (
      callback: () => Promiseable<unknown>,
      {
        rethrow = false,
        onError,
        errorTags,
      }: {
        rethrow?: boolean
        onError?: (error: Error) => unknown
        errorTags?: CaptureExceptionArgs["tags"]
      } = {},
    ) => {
      try {
        await callback()
      } catch (error) {
        const isJsonRpcError = JsonRpcError.isJsonRpcError(error)
        const isUserCancellationOrPending =
          JsonRpcError.isUserCancellationOrPending(error)
        const isWalletRequestConnectionResetError =
          JsonRpcError.isWalletRequestConnectionResetError(error)
        const handleError = async (err: Error) => {
          // JSONRpcError is a special case since it's a wrapper. we want to capture the original error
          if (
            isJsonRpcError &&
            !isUserCancellationOrPending &&
            !isWalletRequestConnectionResetError
          ) {
            JsonRpcError.captureOriginalError(error, { tags: errorTags })
            const errorCode = (error.error?.code || error.code)?.toString()
            const REVERT_REASON_CODES = ["-32603", "-32015"]
            if (REVERT_REASON_CODES.includes(errorCode)) {
              const byteString = error.error?.data?.originalError?.data

              const { revertReason, errorStringRevertReason } =
                await getTransactionRevertReason(byteString ?? "")

              showErrorMessage(
                getErrorString(
                  errorStringRevertReason ??
                    revertReason ??
                    "DEFAULT_ERROR_STRING",
                ),
              )
            } else {
              const responseErrors = maybeGetGraphQLResponseErrors(err)
              await showErrorMessages(
                (responseErrors.length ? responseErrors : [err]).map(
                  e => e.message,
                ),
              )
            }
            onError?.(err)
          } else if (isUserCancellationOrPending) {
            showWarningMessage(err.message)
            onError?.(err)
          } else if (!isWalletRequestConnectionResetError) {
            captureNoncriticalError(error, { tags: errorTags })
            const responseErrors = maybeGetGraphQLResponseErrors(err)
            await showErrorMessages(
              (responseErrors.length ? responseErrors : [err]).map(
                e => e.message,
              ),
            )
            onError?.(err)
          }
          if (rethrow) {
            throw error
          }
        }

        handleError(
          isJsonRpcError || isUserCancellationOrPending
            ? new JsonRpcError(error)
            : error,
        )
      }
    },
    [
      getTransactionRevertReason,
      showErrorMessage,
      getErrorString,
      showErrorMessages,
      showWarningMessage,
    ],
  )

  const value = useMemo(
    () => ({
      addToasts,
      removeToast,
      setToastXOffset,
      setToastYOffset,
      showErrorMessages,
      showErrorMessage,
      showInfoMessage,
      showSuccessMessage,
      showWarningMessage,
      toastXOffset,
      toastYOffset,
      attempt,
    }),
    [
      attempt,
      addToasts,
      removeToast,
      showErrorMessage,
      showErrorMessages,
      showInfoMessage,
      showSuccessMessage,
      showWarningMessage,
      toastXOffset,
      toastYOffset,
    ],
  )

  return (
    <ToastContext.Provider value={value}>
      {children}
      <ToastsContainer toasts={toasts} />
    </ToastContext.Provider>
  )
}
