/**
 * @generated SignedSource<<b6d99bf6c133bd0b9302deb51424c81f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ErrorStringReverts = "APE_MOVE_ERC721_ERROR_STRING_1" | "CUSTOM_ERROR_STRING_1" | "CUSTOM_ERROR_STRING_2" | "ERC1155Pausable_ERROR_STRING_1" | "ERC1155_ERROR_STRING_1" | "ERC1155_ERROR_STRING_2" | "ERC1155_ERROR_STRING_3" | "ERC1155_ERROR_STRING_4" | "ERC20_ERROR_STRING_1" | "ERC20_ERROR_STRING_2" | "ERC20_ERROR_STRING_3" | "ERC20_ERROR_STRING_4" | "ERC721A_ERROR_STRING_1" | "ERC721Pausable_ERROR_STRING_1" | "ERC721_ERROR_STRING_1" | "ERC721_ERROR_STRING_2" | "ERC721_ERROR_STRING_3" | "ERC721_ERROR_STRING_4" | "ERC721_ERROR_STRING_5" | "ERC721_ERROR_STRING_6" | "HUNTER_IS_LOCKED_ERROR_STRING" | "NFT_IS_STAKED_ERROR_STRING" | "PAUSABLE_PAUSED_ERROR_STRING" | "STAR_NFT_ERROR_STRING" | "%future added value";
export type InvalidTimeReverts = "INVALID_TIME_EXPIRED" | "INVALID_TIME_NOT_STARTED" | "%future added value";
export type RevertReason = "ADDRESS_FILTERED" | "DEFAULT_ERROR_STRING" | "ERROR_STRING" | "GATEKEEP" | "GUARDIAN_ENABLED" | "INEXACT_FRACTION" | "INVALID_MSG_VALUE" | "INVALID_SIGNER" | "INVALID_TIME" | "NON_TRANSFERRABLE_TOKEN" | "NOT_ALLOWED" | "NOT_TRANSFERRABLE" | "ORDER_ALREADY_FILLED" | "ORDER_CANNOT_BE_PARTIALLY_FILLED" | "TOKEN_IS_LOCKED" | "TOKEN_IS_SOUL_BOUND" | "TOKEN_TRANSFER_GENERIC_FAILURE" | "TRANSFER_CALLER_NOT_OWNER_NOR_APPROVED" | "TRANSFER_DISABLED" | "TRANSFER_FROM_INCORRECT_OWNER" | "%future added value";
export type useGetTransactionRevertReasonQuery$variables = {
  revertReasonBytesStr: string;
};
export type useGetTransactionRevertReasonQuery$data = {
  readonly transactionRevertReason: {
    readonly errorStringRevertReason?: ErrorStringReverts;
    readonly invalidTimeRevertReason?: InvalidTimeReverts;
    readonly revertReason?: RevertReason;
  };
};
export type useGetTransactionRevertReasonQuery = {
  response: useGetTransactionRevertReasonQuery$data;
  variables: useGetTransactionRevertReasonQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "revertReasonBytesStr"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "revertReasonBytesStr",
    "variableName": "revertReasonBytesStr"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": "revertReason",
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    }
  ],
  "type": "RevertReasonType",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": "errorStringRevertReason",
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    }
  ],
  "type": "ErrorStringRevertsType",
  "abstractKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": "invalidTimeRevertReason",
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    }
  ],
  "type": "InvalidTimeRevertsType",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useGetTransactionRevertReasonQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "transactionRevertReason",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useGetTransactionRevertReasonQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "transactionRevertReason",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "57c1fccc5035c1ede6e3aa60220e10fe",
    "id": null,
    "metadata": {},
    "name": "useGetTransactionRevertReasonQuery",
    "operationKind": "query",
    "text": "query useGetTransactionRevertReasonQuery(\n  $revertReasonBytesStr: String!\n) {\n  transactionRevertReason(revertReasonBytesStr: $revertReasonBytesStr) {\n    __typename\n    ... on RevertReasonType {\n      revertReason: reason\n    }\n    ... on ErrorStringRevertsType {\n      errorStringRevertReason: reason\n    }\n    ... on InvalidTimeRevertsType {\n      invalidTimeRevertReason: reason\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4ca43e61c30c732e7b1909ed6c171b37";

export default node;
