import React, { useEffect } from "react"
import { NetworkUnsupportedGate } from "@/components/modals/NetworkUnsupportedGate"
import { WALLET_NAME } from "@/constants/wallet"
import { Z_INDEX } from "@/constants/zIndex"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { MAX_BANNER_HEIGHT, useAnnouncementBanner } from "../utils/context"
import { NewFeatureAnnouncementBannerDisplay } from "./NewFeatureAnnouncementBanner.react"

export const AlphaAnnouncementBanner = () => {
  const t = useTranslate("home")
  const { os2Login, provider } = useWallet()
  const { showInfoMessage } = useToasts()
  const { setAnnouncementBannerHeight, setIsAnnouncementBannerShown } =
    useAnnouncementBanner()

  useEffect(() => {
    setAnnouncementBannerHeight(MAX_BANNER_HEIGHT)
    setIsAnnouncementBannerShown(true)

    return () => {
      setIsAnnouncementBannerShown(false)
    }
  }, [setAnnouncementBannerHeight, setIsAnnouncementBannerShown])

  const ctaText = t("os2Banner.button", "Try OS2")
  const heading = t("os2Banner.title", "The tides are changing. Are you ready?")
  const toast = t("os2Banner.toast", "Sign message in wallet")

  return (
    <header
      style={{
        zIndex: Z_INDEX.NAVBAR + 1,
        top: 0,
        position: "sticky",
      }}
    >
      <NetworkUnsupportedGate>
        {({ handleIfNotSupported }) => (
          <NewFeatureAnnouncementBannerDisplay
            ctaText={ctaText}
            heading={heading}
            headingMobile={heading}
            icon={null}
            isClosable={false}
            text=""
            url={null}
            onClick={handleIfNotSupported(async () => {
              if (provider?.getName() !== WALLET_NAME.OpenSeaWallet) {
                showInfoMessage(toast)
              }
              await os2Login()
              // Refresh the page so they can be redirected to OS2
              window.location.reload()
            })}
          />
        )}
      </NetworkUnsupportedGate>
    </header>
  )
}
