import React, { useState } from "react"
import { breakpoints, classNames, FlexColumn } from "@opensea/ui-kit"
import { useWindowWidth } from "@react-hook/window-size"
import styled from "styled-components"
import Footer from "@/components/layout/Footer.react"
import { Navbar } from "@/components/nav/Navbar/Navbar.react"
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from "@/constants/index"
import { AlphaAnnouncementBanner } from "@/features/announcement-banner/components/AlphaAnnouncementBanner.react"
import {
  AnnouncementBanner,
  AnnouncementBannerProps,
} from "@/features/announcement-banner/components/AnnouncementBanner.react"
import { useShowOs2AnnouncementBanner } from "@/features/primary-drops/components/OS2HomepageTakeover/useShowOS2AnnouncementBanner"
import { AcceptTermsOfServiceModal } from "@/features/terms-of-service/components/AcceptTermsOfServiceModal/"
import { useAppContext } from "@/hooks/useAppContext"
import { useIsTosModalEnabled } from "@/hooks/useFlag"
import { useIsIosWebview } from "@/hooks/useIsIosWebview"
import { useRouter } from "@/hooks/useRouter"
import { $nav_height } from "@/styles/variables"
import { useActiveAccount } from "./WalletProvider/WalletProvider.react"

type Props = {
  className?: string
  hideFooter?: boolean
  searchQuery?: string
  children?: React.ReactNode
  hasTransparentNavbar?: boolean
  renderNavigation?: () => React.ReactNode
  announcementBannerProps?: AnnouncementBannerProps
  isNavbarSticky?: boolean
}

const THREE_SECONDS = 3000

export const OpenSeaPage = ({
  announcementBannerProps,
  className,
  hideFooter,
  searchQuery,
  children,
  hasTransparentNavbar = false,
  renderNavigation,
  isNavbarSticky = true,
}: Props) => {
  const { isWalletSidebarOpen } = useAppContext()
  const activeAccount = useActiveAccount()

  const isTosModalEnabled = useIsTosModalEnabled()
  const router = useRouter()
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false)
  const showAlphaAnnouncementBanner = useShowOs2AnnouncementBanner()

  const showAcceptTosModal =
    isTosModalEnabled &&
    activeAccount &&
    !activeAccount.user?.hasAffirmativelyAcceptedOpenseaTerms &&
    !activeAccount.metadata?.isBanned &&
    !activeAccount.metadata?.isDisabled &&
    ![PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL].includes(router.pathname)

  const windowWidth = useWindowWidth({ wait: THREE_SECONDS })
  const isFullScreenMobileMenuVisible =
    windowWidth < breakpoints.lg && isMobileMenuVisible
  const isIosWebview = useIsIosWebview()

  return (
    <DivContainer>
      {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
      <a className="AppContainer--skip-link" href="#main">
        Skip to main content
      </a>
      {showAlphaAnnouncementBanner && <AlphaAnnouncementBanner />}
      <AnnouncementBanner {...announcementBannerProps} />
      {!isIosWebview &&
        (renderNavigation ? (
          renderNavigation()
        ) : (
          <Navbar
            isMobileMenuVisible={isMobileMenuVisible}
            isSticky={isNavbarSticky}
            isTransparent={hasTransparentNavbar}
            isWalletSidebarOpen={isWalletSidebarOpen}
            searchQuery={searchQuery}
            setIsMobileMenuVisible={setIsMobileMenuVisible}
          />
        ))}

      <FlexColumn
        className="flex-1"
        id="main"
        style={{
          display: isFullScreenMobileMenuVisible ? "none" : undefined,
        }}
      >
        <main>
          <FlexColumn className={classNames("h-full flex-1", className)}>
            {children}
          </FlexColumn>
        </main>
      </FlexColumn>
      {!hideFooter && !isFullScreenMobileMenuVisible && <Footer />}
      {showAcceptTosModal && <AcceptTermsOfServiceModal />}
    </DivContainer>
  )
}

const DivContainer = styled(FlexColumn)`
  min-height: 100%;

  .AppContainer--skip-link {
    left: -999px;
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;

    &:focus,
    &:active {
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.primary};
      left: auto;
      top: auto;
      width: 30%;
      height: auto;
      overflow: auto;
      margin: 10px 35%;
      padding: 5px;
      border-radius: ${props => props.theme.borderRadius.default};
      text-align: center;
      font-size: 1.2em;
      z-index: 999;
    }
  }

  .AppContainer--embed-container {
    max-height: calc(100vh - ${$nav_height});
  }

  .AppContainer--mainWrapper {
    height: calc(100% - ${$nav_height});
  }
`
