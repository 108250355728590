import React from "react"
import { Icon, classNames } from "@opensea/ui-kit"
import { ClassValue } from "clsx"
import styled from "styled-components"
import { variant } from "styled-system"
import { BlockscoutLogo } from "@/components/svgs/BlockscoutLogo.react"
import { KlaytnLogo } from "@/components/svgs/ChainLogos/KlaytnLogo.react"
import { PolygonLogo } from "@/components/svgs/ChainLogos/PolygonLogo.react"
import { SeiLogo } from "@/components/svgs/ChainLogos/SeiLogo.react"
import { SolanaLogo } from "@/components/svgs/ChainLogos/SolanaLogo.react"
import { DiscordLogo } from "@/components/svgs/DiscordLogo.react"
import { EtherscanLogo } from "@/components/svgs/EtherscanLogo.react"
import { FacebookLogo } from "@/components/svgs/FacebookLogo.react"
import { GitHubLogo } from "@/components/svgs/GitHubLogo.react"
import { GunzscanLogo } from "@/components/svgs/GunzscanLogo.react"
import { InstagramLogo } from "@/components/svgs/InstagramLogo.react"
import { LinkedInLogo } from "@/components/svgs/LinkedInLogo.react"
import { MediumLogo } from "@/components/svgs/MediumLogo.react"
import { OpenSeaLogo } from "@/components/svgs/OpenSeaLogo.react"
import { RedditLogo } from "@/components/svgs/RedditLogo.react"
import { TelegramLogo } from "@/components/svgs/TelegramLogo.react"
import { TikTokLogo } from "@/components/svgs/TikTokLogo.react"
import { TwitterLogo } from "@/components/svgs/TwitterLogo.react"
import { WebpageLogo } from "@/components/svgs/WebpageLogo.react"
import { WebsiteExternalIcon } from "@/components/svgs/WebsiteExternalIcon.react"
import { YoutubeLogo } from "@/components/svgs/YoutubeLogo.react"
import { Flex } from "@/design-system/Flex"
import { BlockExplorerName } from "@/lib/graphql/__generated__/SocialBar_data.graphql"
import { Theme } from "@/styles/styled"
import THEMES, { HUES } from "@/styles/themes"
import { selectClassNames } from "./styling"

export type FillColor =
  | "gray"
  | "white"
  | "fog"
  | "seaBlue"
  | "charcoal"
  | "text-secondary"

type SocialColor =
  | "twitter"
  | "discord"
  | "instagram"
  | "reddit"
  | "youtube"
  | "tiktok"

type SocialName =
  | "activity"
  | "mail"
  | "website"
  | "website_external"
  | "discord"
  | "twitter"
  | "instagram"
  | "medium"
  | "youtube"
  | "tiktok"
  | "telegram"
  | "github"
  | "reddit"
  | "linkedin"
  | "opensea"
  | "facebook"

export type IconName = SocialName | BlockExplorerName

export type Social = {
  name: IconName
  fill?: FillColor
  logoFill?: FillColor | SocialColor
  height?: number
  width?: number
  withHoverChange?: boolean
  theme?: Theme
  className?: ClassValue
}

export const getSocialIcon = ({
  name,
  fill = "white",
  logoFill,
  width = 20,
  height = 20,
  withHoverChange,
  theme,
  className,
}: Social) => {
  // TODO(guscost-opensea): Make this logic special icon colors less confusing
  // The iconColor logic is only used for "activity" and "mail" icons below
  const iconColor = fill === "seaBlue" ? "text-blue-3" : fill
  const resolvedLogoFill = logoFill ?? fill
  const logoColor =
    resolvedLogoFill === "text-secondary" && theme
      ? THEMES[theme].colors.text.secondary
      : HUES[
          resolvedLogoFill as Exclude<FillColor, "text-secondary"> | SocialColor
        ]

  const iconClass = classNames(
    selectClassNames("Icon", { withHoverChange }),
    className,
  )

  const socialIcons = {
    activity: (
      <Icon
        className={classNames(iconColor, className)}
        size={width}
        value="playlist_play"
      />
    ),
    mail: (
      <Icon
        className={classNames(iconColor, className)}
        size={width}
        value="mail"
      />
    ),
    website: (
      <WebpageLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    // Square with arrow coming up and to the right
    website_external: (
      <WebsiteExternalIcon
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    discord: (
      <DiscordLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    twitter: (
      <TwitterLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    instagram: (
      <InstagramLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    medium: (
      <MediumLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    youtube: (
      <YoutubeLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    tiktok: (
      <TikTokLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    telegram: (
      <TelegramLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    github: (
      <GitHubLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    reddit: (
      <RedditLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    linkedin: (
      <LinkedInLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    opensea: (
      <OpenSeaLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    facebook: (
      <FacebookLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    POLYGONSCAN: (
      <PolygonLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    KLAYTNSCOPE: (
      <KlaytnLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    ETHERSCAN: (
      <EtherscanLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    SOLANA_EXPLORER: (
      <SolanaLogo className={iconClass} fill={logoColor} width={width} />
    ),
    BLOCKSCOUT: (
      <BlockscoutLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    // Just use the same logo for all Etherscan derivatives. Make this more complex later if needed.
    BSCSCAN: (
      <EtherscanLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    FLOWSCAN: (
      <EtherscanLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    ARBISCAN: (
      <EtherscanLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    SNOWTRACE: (
      <EtherscanLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    OPTIMISTIC_ETHERSCAN: (
      <EtherscanLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    BASESCAN: (
      <EtherscanLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    ROUTESCAN: (
      <EtherscanLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    BLASTSCAN: (
      <EtherscanLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    GUNZSCAN: (
      <GunzscanLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    // Use polygon logo for OKLink for now given no SVG for OKLINK
    OKLINK: (
      <PolygonLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    SEITRACE: (
      <SeiLogo
        className={iconClass}
        fill={logoColor}
        height={height}
        width={width}
      />
    ),
    "%future added value": <></>,
  }
  return <DivContainer $fill={fill}>{socialIcons[name]}</DivContainer>
}

const DivContainer = styled(Flex)<{ $fill: FillColor }>`
  .Icon--withHoverChange:hover {
    ${props =>
      variant({
        prop: "$fill",
        variants: {
          [props.theme.colors.seaBlue]: {
            fill: props.theme.colors.darkSeaBlue,
          },
          [props.theme.colors.fog]: {
            fill: props.theme.colors.white,
          },
          [props.theme.colors.gray]: {
            fill: props.theme.colors.darkGray,
          },
          [props.theme.colors.charcoal]: {
            fill: props.theme.colors.darkGray,
          },
        },
      })}
  }
`
