import React, { MouseEvent, useMemo } from "react"
import { MaterialIcon } from "@opensea/ui-kit"
import {
  BLOG_URL,
  CategorySlug,
  DISCORD_URL,
  DOCS_URL,
  HELP_CENTER_URL,
  INSTAGRAM_URL,
  LEARN_URL,
  NEWSLETTER_URL,
  PLATFORM_STATUS_URL,
  REDDIT_URL,
  TIKTOK_URL,
  TWITTER_URL,
  COMMUNITY_STANDARDS_URL,
  YOUTUBE_URL,
  PARTNERS_URL,
} from "@/constants"
import { OPENSEA_PRO_URL } from "@/constants/urls"
import { DEAL_CREATE_URL } from "@/features/deals/urls"
import { WATCHLIST_PATH } from "@/features/rankings/constants"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import {
  useIsMintYourOwnEnabled,
  useIsSSFEnabled,
  useOSProGlobalLinkingExperience,
} from "@/hooks/useFlag"
import { useTranslate } from "@/hooks/useTranslate"
import * as I18nConfig from "@/i18n"
import { getSocialIcon, Social } from "./icons"

type LinkItemBase = {
  label: string
  onClick?: (e: MouseEvent<HTMLElement>) => void
  sideContent?: React.ReactNode
}

type LinkItemWithImage = LinkItemBase & {
  url?: string
  image?: string
  trendingData?: {
    category: CategorySlug | undefined
    chain: ChainIdentifier | undefined
  }
  icon?: never
  locale?: never
}

type LinkItemWithIcon = LinkItemBase & {
  url?: string
  icon?: MaterialIcon
  image?: never
  locale?: never
  trendingData?: never
}

export type LinkWithLocale = LinkItemBase & {
  url?: never
  icon?: never
  image?: never
  trendingData?: never
  locale?: string
}

export type LinkItem = LinkItemWithImage | LinkItemWithIcon | LinkWithLocale
export type LinkArray = LinkItem[]
export type TrendingArray = LinkItemWithImage[]

const useCategories: () => Array<{
  label: string
  slug: CategorySlug
}> = () => {
  const t = useTranslate("common")

  return useMemo(
    () => [
      {
        label: t("categories.art", "Art"),
        slug: "art",
      },
      {
        label: t("categories.gaming", "Gaming"),
        slug: "gaming",
      },
      {
        label: t("categories.memberships", "Memberships"),
        slug: "memberships",
      },
      {
        label: t("categories.pfps", "PFPs"),
        slug: "pfps",
      },
      {
        label: t("categories.photography", "Photography"),
        slug: "photography",
      },
      {
        label: t("categories.music", "Music"),
        slug: "music",
      },
    ],
    [t],
  )
}

export const useExploreLinks = () => {
  const categories = useCategories()

  return useMemo(
    () =>
      categories.map(category => ({
        url: `/category/${category.slug}`,
        label: category.label,
        image: `/static/images/icons/${category.slug}`,
        trendingData: {
          category: category.slug,
          chain: undefined,
        },
      })),
    [categories],
  )
}

type AccountLinks = {
  profile: LinkItem
  deals?: LinkItem
  favorites: LinkItem
  watchlist: LinkItem
  collections: LinkItem
  create?: LinkItem
  openseaPro?: LinkItem
  creatorStudio: LinkItem
  settings: LinkItem
  learn: LinkItem
  helpCenter: LinkItem
}

export const useNavLinks: () => AccountLinks = () => {
  const t = useTranslate("common")
  const osProGlobalLinkingExperience = useOSProGlobalLinkingExperience()
  const isSSFEnabled = useIsSSFEnabled()
  const shouldShowOSProLink = osProGlobalLinkingExperience !== "disabled"

  return useMemo(
    () => ({
      profile: {
        url: "/account",
        label: t("myAccountLinks.profile", "Profile"),
        icon: "person",
      },
      deals: {
        url: DEAL_CREATE_URL,
        label: t("myAccountLinks.deals", "Deals"),
        icon: "handshake",
      },
      creatorStudio: {
        url: "/studio",
        label: t("myAccountLinks.creatorStudiov2", "Studio"),
        icon: "design_services",
      },
      favorites: {
        url: "/account/favorites",
        label: t("myAccountLinks.favorites", "Favorites"),
        icon: "favorite",
      },
      watchlist: {
        url: WATCHLIST_PATH,
        label: t("myAccountLinks.watchlist", "Watchlist"),
        icon: "visibility",
      },
      collections: {
        url: "/collections",
        label: t("myAccountLinks.myCollections", "My collections"),
        icon: "grid_on",
      },
      create: isSSFEnabled
        ? {
            url: "/asset/create",
            label: t("resourceLinks.create", "Create"),
            icon: "edit",
          }
        : undefined,
      openseaPro: shouldShowOSProLink
        ? {
            onClick: undefined,
            url: OPENSEA_PRO_URL,
            label: t("myAccountLinks.openseaPro", "OpenSea Pro"),
            image: "/static/images/icons/opensea-pro",
          }
        : undefined,
      settings: {
        url: "/account/settings",
        label: t("myAccountLinks.settings", "Settings"),
        icon: "settings",
      },
      learn: {
        url: LEARN_URL,
        label: t("learnLinks.learn", "Learn"),
        icon: "library_books",
      },
      helpCenter: {
        url: HELP_CENTER_URL,
        label: t("learnLinks.helpCenter", "Help center"),
        icon: "contact_support",
      },
    }),
    [isSSFEnabled, shouldShowOSProLink, t],
  )
}

export const useMyAccountLinks: () => LinkItem[] = () => {
  const links = useNavLinks()
  const isMintYourOwnEnabled = useIsMintYourOwnEnabled()
  return useMemo(
    () => [
      links.profile,
      links.favorites,
      links.watchlist,
      ...(isMintYourOwnEnabled ? [links.creatorStudio] : [links.collections]),
      ...(links.openseaPro ? [links.openseaPro] : []),
      ...(links.create ? [links.create] : []),
      links.settings,
    ],
    [links, isMintYourOwnEnabled],
  )
}

// Note (@auster-eth): Languge labels don't need to be translated, but we use a hook for consistency purposes
export const useLanguageLinks = () => {
  return I18nConfig.selectorOptions
}

export const useLearnLinks = () => {
  const t = useTranslate("common")

  return useMemo(
    () => [
      {
        url: LEARN_URL,
        label: t("learnLinks.learn", "Learn"),
        icon: "school" as MaterialIcon,
      },
      {
        url: HELP_CENTER_URL,
        label: t("learnLinks.helpCenter", "Help center"),
        icon: "library_books" as MaterialIcon,
      },
    ],
    [t],
  )
}

export const useResourceLinks = () => {
  const t = useTranslate("common")

  return useMemo(
    () => [
      {
        url: BLOG_URL,
        label: t("resourceLinks.blog", "Blog"),
      },
      {
        url: LEARN_URL,
        label: t("resourceLinks.learn", "Learn"),
      },
      {
        url: HELP_CENTER_URL,
        label: t("resourceLinks.helpCenter", "Help center"),
      },
      {
        url: COMMUNITY_STANDARDS_URL,
        label: t("resourceLinks.communityStandards", "Community standards"),
      },
      {
        url: "/tax-resources",
        label: t("resourceLinks.taxes", "Taxes"),
      },
      {
        url: PARTNERS_URL,
        label: t("resourceLinks.partners", "Partners"),
      },
      {
        url: DOCS_URL,
        label: t("resourceLinks.developerPlatform", "Developer platform"),
      },
      {
        url: PLATFORM_STATUS_URL,
        label: t("resourceLinks.platformStatus", "Platform status"),
      },
    ],
    [t],
  )
}

export const useStatLinks = () => {
  const t = useTranslate("common")

  return useMemo(
    () => [
      {
        url: "/rankings",
        label: t("statLinks.rankings", "Rankings"),
      },
      {
        url: "/activity",
        label: t("statLinks.activity", "Activity"),
      },
    ],
    [t],
  )
}

export const useDropLinks = () => {
  const t = useTranslate("common")

  return useMemo(
    () => [
      {
        url: "/drops",
        label: t("dropLinks.featured", "Featured"),
      },
      {
        url: "https://opensea.io/partners",
        label: t("dropLinks.learnMore", "Learn more"),
      },
    ],
    [t],
  )
}

type SocialLink = {
  url: string
  logo: React.ReactNode
  label: string
  external: boolean
}

export const getSocialLinks = ({
  width,
  fill,
  withHoverChange,
  theme,
}: Omit<Social, "name">): SocialLink[] => {
  return [
    {
      url: TWITTER_URL,
      logo: getSocialIcon({
        name: "twitter",
        width,
        logoFill: fill ?? "twitter",
        withHoverChange,
        theme,
      }),
      label: "Twitter Official",
      external: true,
    },
    {
      url: INSTAGRAM_URL,
      logo: getSocialIcon({
        name: "instagram",
        width,
        logoFill: fill ?? "instagram",
        withHoverChange,
        theme,
      }),
      label: "Instagram",
      external: true,
    },
    {
      url: DISCORD_URL,
      logo: getSocialIcon({
        name: "discord",
        width,
        logoFill: fill ?? "discord",
        withHoverChange,
        theme,
      }),
      label: "Discord",
      external: true,
    },
    {
      url: REDDIT_URL,
      logo: getSocialIcon({
        name: "reddit",
        width,
        logoFill: fill ?? "reddit",
        withHoverChange,
        theme,
      }),
      label: "Reddit",
      external: true,
    },
    {
      url: YOUTUBE_URL,
      logo: getSocialIcon({
        name: "youtube",
        width,
        logoFill: fill ?? "youtube",
        withHoverChange,
        theme,
      }),
      label: "YouTube",
      external: true,
    },
    {
      url: TIKTOK_URL,
      logo: getSocialIcon({
        name: "tiktok",
        width,
        logoFill: fill ?? "tiktok",
        withHoverChange,
        theme,
      }),
      label: "TikTok",
      external: true,
    },
    {
      url: NEWSLETTER_URL,
      logo: getSocialIcon({
        name: "mail",
        width,
        fill,
        withHoverChange,
        theme,
      }),
      label: "Mail",
      external: false,
    },
  ]
}

export const getSocialLinksWithoutNewsletter = ({
  width,
  fill,
  withHoverChange,
  theme,
}: Omit<Social, "name">): SocialLink[] =>
  getSocialLinks({ width, fill, withHoverChange, theme }).slice(0, 6)
