import React, { useMemo, memo, useCallback } from "react"
import { useIsLessThanSm, useTheme } from "@opensea/ui-kit"
import dynamic from "next/dynamic"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { BigNumber, NumberInput, bn } from "@/lib/helpers/numberUtils"
import { MOONPAY_THEME_ID } from "../../constants"
import { fetchMoonPayUrlSignature } from "../../utils/fetchMoonPayUrlSignature"
import { WIDGET_HEIGHT, WIDGET_MAX_HEIGHT } from "../constants"
import { getDefaultMoonPayCurrencyCode, getMoonPayCurrencyCode } from "./utils"

const MoonPaySdkBuyWidget = memo(
  dynamic(
    () => import("@moonpay/moonpay-react").then(mod => mod.MoonPayBuyWidget),
    { ssr: false },
  ),
)

export type MoonPayBuyModalProps =
  | {
      symbol: string
      chain: ChainIdentifier
      amount?: NumberInput
      onTransactionCompleted?: () => unknown
    }
  | Record<string, never>

// MoonPay only supports up to 5 decimal places for quote currency amount
const MAX_QUOTE_CURRENCY_DECIMALS = 5

export const MoonPayBuyModal = ({
  symbol,
  chain,
  amount,
  onTransactionCompleted,
}: MoonPayBuyModalProps) => {
  const { theme } = useTheme()
  const isSmallScreen = useIsLessThanSm()

  const defaultCurrencyCode = getDefaultMoonPayCurrencyCode(chain)
  const currencyCode = symbol
    ? getMoonPayCurrencyCode(symbol, chain)
    : undefined
  const quoteCurrencyAmount = amount
    ? bn(amount)
        .decimalPlaces(MAX_QUOTE_CURRENCY_DECIMALS, BigNumber.ROUND_UP)
        .toString()
    : undefined

  const customStyles: React.CSSProperties = useMemo(() => {
    const styles: React.CSSProperties = {
      padding: "8px",
      border: "none",
      margin: 0,
      width: "100%",
      height: WIDGET_HEIGHT,
      maxHeight: WIDGET_MAX_HEIGHT,
    }
    if (isSmallScreen) {
      styles.borderBottomLeftRadius = 0
      styles.borderBottomRightRadius = 0
    }
    return styles
  }, [isSmallScreen])

  const connectedAddress = useConnectedAddress()

  const onUrlSignatureRequested = useCallback(
    async (url: string): Promise<string> => {
      const signature = await fetchMoonPayUrlSignature(url, "CRYPTO")
      return signature
    },
    [],
  )

  const walletAddresses = useMemo(() => {
    // https://dev.moonpay.com/docs/ramps-sdk-buy-params
    const addresses = {
      eth: connectedAddress,
      matic_polygon: connectedAddress,
    }

    return JSON.stringify(addresses)
  }, [connectedAddress])

  return (
    <MoonPaySdkBuyWidget
      currencyCode={currencyCode}
      defaultCurrencyCode={defaultCurrencyCode}
      quoteCurrencyAmount={quoteCurrencyAmount}
      showAllCurrencies="true"
      style={customStyles}
      theme={theme}
      themeId={MOONPAY_THEME_ID}
      variant="embedded"
      visible
      walletAddresses={walletAddresses}
      onTransactionCompleted={async () => {
        onTransactionCompleted?.()
      }}
      onUrlSignatureRequested={onUrlSignatureRequested}
    />
  )
}
