import {
  ThemeVariant,
  getTheme as getUIKitTheme,
  breakpoints,
} from "@opensea/ui-kit"
import { addYears } from "date-fns"
import { NextPageContext } from "next"
import { DefaultTheme } from "styled-components"
import Cookie from "@/lib/cookie"
import { map } from "@/lib/helpers/object"
import Router from "@/lib/helpers/router"
import { Theme, ThemeBreakpoints } from "../styles/styled"

const THEME_COOKIE_KEY = "theme"

export const persistThemeSelection = (theme: Theme) => {
  return new Cookie(THEME_COOKIE_KEY).set(
    { theme },
    { expires: addYears(new Date(), 10) },
  )
}

export const getThemeFromCookie = (
  ctx?: NextPageContext,
): Theme | undefined => {
  const themeParam = Router.getQueryParams().theme
  const themeCookie: Cookie<{ theme: Theme }> = new Cookie(THEME_COOKIE_KEY)
  const requestedTheme = themeParam || themeCookie.get(ctx)?.theme

  return requestedTheme === "light" || requestedTheme === "dark"
    ? requestedTheme
    : undefined
}

export const HUES = {
  darkSeaBlue: "#1868B7",
  seaBlue: "#2081E2",
  marina: "#15B2E5",
  lightMarina: "#F3FBFE",
  aqua: "#2BCDE4",
  fog: "#E5E8EB",
  white: "#FFFFFF",
  black: "#000000",
  skyBlue: "#2E8EEE",

  charcoal: "#04111D",
  oil: "#353840",
  darkGray: "#646D75",
  gray: "#8A939B",
  lightGray: "#F7F7F7",
  mist: "#F2F4F5",
  silverChalice: "#9F9F9F",
  pearlGray: "#C4C9CD", // TODO: Potential name change
  seaGrass: "#34C77B",
  darkSeaGrass: "#0C954E",
  starFish: "#F6C000",
  darkStarFish: "#C59E10",
  darkCoral: "#E62929",
  coral: "#EB5757",
  lightCoral: "#EB6B6B",
  darkSeaHorse: "#DB6A00",
  seaHorse: "#F2994A",
  lightSeaHorse: "#FDF1E6",
  pinkFish: "#E932BE",
  octopus: "#5D32E9",
  lightOctopus: "#987DF0",

  // positive
  greenBase: "#0C954E",

  // negative
  redBase: "#CA4F2C",

  slate: "#2F3F4E",
  offShore: "#235AA3",

  // Dark mode specific
  midnight: "#202225",
  onyx: "#272A2E",
  ash: "#4C505C",
  ink: "#303339",
  shoreline: "#42A0FF",
  blackPearl: "#151B22",
  smoke: "#121212",
  basin: "#8FC7FF",

  // Socials
  twitter: "#00acee",
  instagram: "#bc2a8d",
  discord: "#5865F2",
  reddit: "#FF4500",
  youtube: "#FF0000",
  tiktok: "#010101",
}

export const COMMON_COLORS = {
  primary: HUES.seaBlue,
  secondary: HUES.marina,
  tertiary: HUES.aqua,
  success: HUES.seaGrass,
  warning: HUES.seaHorse,
  error: HUES.coral,
  ...HUES,
}

const WHITE_TEXT_COLORS = [
  HUES.seaBlue,
  HUES.marina,
  HUES.aqua,
  HUES.charcoal,
  HUES.oil,
  HUES.gray,
  HUES.seaGrass,
  HUES.coral,
  HUES.octopus,
  HUES.pinkFish,
  HUES.oil,
  HUES.midnight,
  HUES.onyx,
  HUES.ash,
  HUES.ink,
]

export const borderRadius = {
  squircle: "6px",
  default: "10px",
  medium: "8px",
  large: "12px",
  button: "12px",
  toast: "12px",
  list: "12px",
  pill: "16px",
  pillLarge: "50px",
  pillXLarge: "100px",
  xlarge: "16px",
  xxlarge: "24px",
  modal: "16px",
  tooltipLarge: "24px",
  circle: "50%",
} as const

const gradients = {
  banner: `linear-gradient(
      164.51deg,
      rgba(164, 252, 188, 0.7) 0%,
      rgba(255, 255, 255, 0) 93.19%
    ),
    linear-gradient(
      165.14deg,
      rgba(23, 149, 188, 0.7) 15.47%,
      rgba(23, 149, 188, 0) 100%
    ),
    rgba(23, 79, 188, 0.84)`,
  openseaPro: `linear-gradient(
    94.99deg,
    #D372DA 13.62%,
    #E3798D 53.55%,
    #F0B15D 98.83%
  )`,
  carousel: {
    overlay: {
      back: {
        default: `linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)`,
        hover: `linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%)`,
      },
      forward: {
        default: `linear-gradient(270deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)`,
        hover: `linear-gradient(270deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%)`,
      },
    },
  },
}

const themeBreakpoints = [
  `${breakpoints.sm}px`,
  `${breakpoints.md}px`,
  `${breakpoints.lg}px`,
  `${breakpoints.xl}px`,
  `${breakpoints.xxl}px`,
] as ThemeBreakpoints

themeBreakpoints.sm = themeBreakpoints[0]
themeBreakpoints.md = themeBreakpoints[1]
themeBreakpoints.lg = themeBreakpoints[2]
themeBreakpoints.xl = themeBreakpoints[3]
themeBreakpoints.xxl = themeBreakpoints[4]

const getColorOn = (color: string) =>
  WHITE_TEXT_COLORS.find(c => color.startsWith(c)) ? HUES.white : HUES.charcoal

const getOpacities = (color: string) => ({
  veryLight: `${color}0F`,
  light: `${color}40`,
  medium: `${color}80`,
  heavy: `${color}BF`,
})

const shadows = {
  button: "0px 2px 10px rgba(0, 0, 0, 0.1)",
  translucentTooltip: `0px 4px 20px rgba(0, 0, 0, 0.1)`,
}

const lightShadows = {
  borderBottom: `0 1px 0 0 ${
    getUIKitTheme("light").colors.components.border.level1
  }`,
  header: "0px 3px 10px rgba(0, 0, 0, 0.06)",
  footer: "0px 1px 10px rgba(0, 0, 0, 0.06)",
}

const darkShadows = {
  borderBottom: `0 1px 0 0 ${
    getUIKitTheme("dark").colors.components.border.level1
  }`,
  header: "0px 1px 2px rgba(255, 255, 255, 0.15)",
  footer: "0px 0px 2px rgba(255, 255, 255, 0.1)",
}

const maxWidth = {
  smallPadding: "min(1280px, calc(100% - 40px))",
  largePadding: "min(1280px, calc(100% - 80px))",
  mobileBanner: "min(360px, calc(100% - 40px))",
  banner: "min(1220px, calc(100% - 96px))",
}

const spacing = {
  unit: 8,
}

const lightTheme: DefaultTheme = (() => {
  const colors = {
    ...COMMON_COLORS,
    surface: HUES.white,
    input: HUES.white,
  }

  const on = map(colors, getColorOn)

  const type: Theme = "light"
  const uiKitTheme = getUIKitTheme(type)

  return {
    variant: "light",
    colors: {
      ...uiKitTheme.colors,
      ...colors,
      text: {
        ...uiKitTheme.colors.text,
        on,
      },
      withOpacity: {
        ...map(colors, getOpacities),
        text: {
          on: map(on, getOpacities),
        },
      },
    },
    shadows: {
      ...shadows,
      ...lightShadows,
    },
    type,
    gradients,
    borderRadius,
    radii: borderRadius,
    breakpoints: themeBreakpoints,
    maxWidth,
    spacing,
    typeface: uiKitTheme.typeface,
  }
})()

const darkTheme: DefaultTheme = (() => {
  // These dark theme colors are WIP, expect them to change once we flesh out our dark theming
  const colors = {
    ...COMMON_COLORS,
    surface: HUES.ink,
    input: HUES.oil,
  }

  const text = {}

  const on = map(colors, getColorOn)

  const type: Theme = "dark"
  const uiKitTheme = getUIKitTheme(type)

  return {
    variant: "dark",
    colors: {
      ...uiKitTheme.colors,
      ...colors,
      text: {
        ...uiKitTheme.colors.text,
        ...text,
        on,
      },
      withOpacity: {
        ...map(colors, getOpacities),
        text: {
          ...map(text, getOpacities),
          on: map(on, getOpacities),
        },
      },
    },
    shadows: {
      ...shadows,
      ...darkShadows,
    },
    type,
    gradients,
    borderRadius,
    radii: borderRadius,
    breakpoints: themeBreakpoints,
    maxWidth,
    spacing,
    typeface: uiKitTheme.typeface,
  }
})()

const THEMES: Record<ThemeVariant, DefaultTheme> = {
  light: lightTheme,
  dark: darkTheme,
}

export default THEMES
