import type { ChainIdentifier } from "@/hooks/useChains/types"
import {
  MOONPAY_API_BASE_QS,
  MOONPAY_API_BASE_URL,
  MOONPAY_NFT_API_BASE_QS,
} from "../../constants"

type GetMoonPayApiRetrieveExtTxUrlOptions = {
  useNftApiKey?: boolean
}

export const getMoonPayApiRetrieveExtTxUrl = (
  externalTransactionId: string,
  { useNftApiKey = false }: GetMoonPayApiRetrieveExtTxUrlOptions = {},
) => {
  return `${MOONPAY_API_BASE_URL}/v1/transactions/ext/${externalTransactionId}?${
    useNftApiKey ? MOONPAY_NFT_API_BASE_QS : MOONPAY_API_BASE_QS
  }`
}

export const getCurrencyCode = (symbol?: string, chain?: ChainIdentifier) => {
  if (!symbol) {
    return undefined
  }

  if (chain === "ETHEREUM") {
    return symbol.toLowerCase()
  } else if (chain === "MATIC") {
    if (symbol === "MATIC" || symbol === "POL") {
      return "matic_polygon"
    }
    // we only support ETH on Polygon right now
    return "eth_polygon"
  } else if (chain === "SOLANA") {
    // we only support SOL on Solana right now
    return "sol"
  } else {
    // unset default selected token to avoid leading user to buy the token on the wrong chain
    return undefined
  }
}
