import { useEffect, useState } from "react"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { useRouter } from "@/hooks/useRouter"
import { osSiweAdapter } from "@/lib/auth/siwe"

export const useShowOs2AnnouncementBanner = () => {
  const connectedAddress = useConnectedAddress()
  const { pathname } = useRouter()
  const [show, setShow] = useState(false)

  useEffect(() => {
    // Never show banner on studio, covers up header.
    if (pathname.startsWith("/studio")) {
      setShow(false)
      return
    }

    if (connectedAddress) {
      osSiweAdapter.getWhitelisted(connectedAddress).then(setShow)
    } else {
      setShow(false)
    }
  }, [connectedAddress, pathname])

  return show
}
