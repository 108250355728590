import { ArbitrumLogo } from "./ArbitrumLogo.react"
import { AvalancheLogo } from "./AvalancheLogo.react"
import { BaseLogo } from "./BaseLogo.react"
import { BinanceLogo } from "./BinanceLogo.react"
import { BlastLogo } from "./BlastLogo.react"
import { EthereumLogo } from "./EthereumLogo.react"
import { GunzillaLogo } from "./GunzillaLogo.react"
import { KlaytnLogo } from "./KlaytnLogo.react"
import { OptimismLogo } from "./OptimismLogo.react"
import { PolygonLogo } from "./PolygonLogo.react"
import { SeiLogo } from "./SeiLogo.react"
import { SolanaLogo } from "./SolanaLogo.react"
import { ZoraLogo } from "./ZoraLogo.react"
import { B3Logo } from "./B3Logo.react"

export const EquallySizedChainLogos = {
  Arbitrum: ArbitrumLogo,
  Avalanche: AvalancheLogo,
  Base: BaseLogo,
  Blast: BlastLogo,
  Gunzilla: GunzillaLogo,
  Sei: SeiLogo,
  Binance: BinanceLogo,
  Ethereum: EthereumLogo,
  Klaytn: KlaytnLogo,
  Optimism: OptimismLogo,
  Polygon: PolygonLogo,
  Solana: SolanaLogo,
  Zora: ZoraLogo,
  B3: B3Logo,
}

export type EquallySizedChainLogo =
  (typeof EquallySizedChainLogos)[keyof typeof EquallySizedChainLogos]
