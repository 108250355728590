import {
  init as amplitudeInit,
  track as amplitudeTrack,
  identify,
  Identify,
  getSessionId,
  setUserId,
} from "@amplitude/analytics-browser"
import {
  IS_DEVELOPMENT,
  IS_PREVIEW,
  IS_SERVER,
  IS_STORYBOOK,
  IS_TEST,
  getEnvironment,
} from "@/constants/environment"
import type { EventProperties, UserProperties } from "../types"
import { AMPLITUDE_API_KEY } from "./constants"

const IS_SUPPORTED_ENV =
  !IS_SERVER &&
  !IS_TEST &&
  !IS_STORYBOOK &&
  !IS_DEVELOPMENT &&
  getEnvironment() !== "test:e2e"

let isInitialized = false

type InitializeAmplitudeOptions = {
  userId: string | undefined
  deviceId: string
}

export const initializeAmplitude = ({
  userId,
  deviceId,
}: InitializeAmplitudeOptions) => {
  if (!IS_SUPPORTED_ENV) {
    // Do not attempt to initialize in non production environments
    return
  }

  if (!AMPLITUDE_API_KEY) {
    console.error(
      "Amplitude: Attempted to initialize client without an API key",
    )
    return
  }

  amplitudeInit(AMPLITUDE_API_KEY, userId, {
    defaultTracking: {
      pageViews: false,
    },
    deviceId,
  })
  isInitialized = true
}

export const isAmplitudeInitialized = () => isInitialized

export const trackAmplitudeEvent = async (
  eventName: string,
  eventProperties?: EventProperties,
) => {
  // Log events instead of tracking in development and preview environments
  if (IS_DEVELOPMENT || IS_PREVIEW) {
    console.info(
      `Amplitude: "${eventName}" occurred with properties: `,
      eventProperties,
    )
    return
  }

  if (!IS_SUPPORTED_ENV) {
    return
  }

  await amplitudeTrack(eventName, eventProperties).promise
}

export const getAmplitudeSessionId = async () => {
  return getSessionId()
}

export const identifyAmplitudeUser = async (
  address: string,
  userProperties: UserProperties,
) => {
  // Log instead of identifying user in development and preview environments
  if (IS_DEVELOPMENT || IS_PREVIEW) {
    console.info(
      `Amplitude: User "${address}" identified with properties: `,
      userProperties,
    )
    return
  }

  if (!IS_SUPPORTED_ENV) {
    return
  }

  if (!isInitialized) {
    console.error(
      "Amplitude: Attempted to identify user with client uninitialized",
    )
    return
  }

  const identifyEvent = new Identify()

  Object.entries(userProperties).forEach(([key, value]) => {
    identifyEvent.set(key, value)
  })

  identify(identifyEvent, { user_id: address })
}

export const resetAmplitudeUser = () => {
  // Log instead of resetting user in development and preview environments
  if (IS_DEVELOPMENT || IS_PREVIEW) {
    console.info("Amplitude: User reset")
    return
  }

  if (!IS_SUPPORTED_ENV) {
    return
  }

  if (!isInitialized) {
    console.error(
      "Amplitude: Attempted to reset user with client uninitialized",
    )
    return
  }

  setUserId(undefined)
}
