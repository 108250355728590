import { useMemo, useCallback } from "react"
import { useIsLessThanSm } from "@opensea/ui-kit"
import {
  ALL_WALLETS,
  getWalletConfiguration,
  WALLET_NAME,
} from "@/constants/wallet"
import { useChains } from "@/hooks/useChains"
import { useDisabledWallets } from "@/hooks/useFlag"

const MOBILE_WALLETS_ORDER = [
  WALLET_NAME.WalletConnect,
  WALLET_NAME.MetaMask,
  WALLET_NAME.LedgerConnect,
  WALLET_NAME.CoinbaseWallet,
  WALLET_NAME.Phantom,
  WALLET_NAME.BitKeep,
  WALLET_NAME.Core,
  WALLET_NAME.Fortmatic,
  WALLET_NAME.Trust,
  WALLET_NAME.Kaikas,
  WALLET_NAME.Bitski,
  WALLET_NAME.OperaTouch,
]

export const useWallets = () => {
  const disabledWalletList = useDisabledWallets() as WALLET_NAME[]
  const isMobile = useIsLessThanSm()
  const { chains, getChain } = useChains()

  const disabledWallets = useMemo(() => {
    const wallets: WALLET_NAME[] = []

    // Always disable OpenSea wallet option since login with email is special cased
    wallets.push(WALLET_NAME.OpenSeaWallet)

    disabledWalletList.map(disabledWalletName => {
      wallets.push(disabledWalletName)
    })

    return wallets
  }, [disabledWalletList])

  const isWalletEnabled = useCallback(
    (wallet: WALLET_NAME) => !disabledWallets.includes(wallet),
    [disabledWallets],
  )

  const wallets = useMemo(() => {
    let supportedWallets = isMobile ? MOBILE_WALLETS_ORDER : ALL_WALLETS
    supportedWallets = supportedWallets.filter(wallet =>
      isWalletEnabled(wallet),
    )

    // filter out wallets that don't support any of the chains
    // e.g if Solana is disabled Phantom will be filtered out as thats the only chain it supports
    supportedWallets = supportedWallets.filter(wallet =>
      chains.some(chain =>
        getWalletConfiguration(wallet).supportsChain(getChain(chain)),
      ),
    )

    return supportedWallets
  }, [isMobile, isWalletEnabled, chains, getChain])

  return { wallets, disabledWallets, isWalletEnabled }
}
