/**
 * @generated SignedSource<<5a7fcf73b4884b1907a600ca646b9731>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChainIdentifier = "AMOY" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SOLANA" | "SOLDEV" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
export type useHandleBlockchainActionsCreateBulkOrderMutation$variables = {
  clientSignature: string;
  orderDatas: string;
  serverSignature: string;
};
export type useHandleBlockchainActionsCreateBulkOrderMutation$data = {
  readonly orders: {
    readonly create: {
      readonly orders: ReadonlyArray<{
        readonly item: {
          readonly __typename: string;
          readonly displayName: string;
          readonly relayId: string;
          readonly " $fragmentSpreads": FragmentRefs<"bundle_url">;
        };
        readonly relayId: string;
      }> | null;
      readonly transaction: {
        readonly blockExplorerLink: string;
        readonly chain: {
          readonly identifier: ChainIdentifier;
        };
        readonly transactionHash: string;
      } | null;
    };
  };
};
export type useHandleBlockchainActionsCreateBulkOrderMutation = {
  response: useHandleBlockchainActionsCreateBulkOrderMutation$data;
  variables: useHandleBlockchainActionsCreateBulkOrderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientSignature"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderDatas"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "serverSignature"
},
v3 = [
  {
    "kind": "Variable",
    "name": "clientSignature",
    "variableName": "clientSignature"
  },
  {
    "kind": "Variable",
    "name": "orderDatas",
    "variableName": "orderDatas"
  },
  {
    "kind": "Variable",
    "name": "serverSignature",
    "variableName": "serverSignature"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "slug",
    "storageKey": null
  },
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blockExplorerLink",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transactionHash",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useHandleBlockchainActionsCreateBulkOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrdersMutationType",
        "kind": "LinkedField",
        "name": "orders",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "OrderMatchedType",
            "kind": "LinkedField",
            "name": "create",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderV2Type",
                "kind": "LinkedField",
                "name": "orders",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "item",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v4/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "bundle_url",
                            "selections": (v8/*: any*/),
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "type": "AssetBundleType",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TransactionType",
                "kind": "LinkedField",
                "name": "transaction",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v7/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "useHandleBlockchainActionsCreateBulkOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrdersMutationType",
        "kind": "LinkedField",
        "name": "orders",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "OrderMatchedType",
            "kind": "LinkedField",
            "name": "create",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderV2Type",
                "kind": "LinkedField",
                "name": "orders",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "item",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v4/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": (v8/*: any*/),
                        "type": "AssetBundleType",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v11/*: any*/)
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TransactionType",
                "kind": "LinkedField",
                "name": "transaction",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v7/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0699745ff1c7882e65a3de215ff8f8c1",
    "id": null,
    "metadata": {},
    "name": "useHandleBlockchainActionsCreateBulkOrderMutation",
    "operationKind": "mutation",
    "text": "mutation useHandleBlockchainActionsCreateBulkOrderMutation(\n  $orderDatas: JSONString!\n  $clientSignature: String!\n  $serverSignature: String!\n) {\n  orders {\n    create(orderDatas: $orderDatas, clientSignature: $clientSignature, serverSignature: $serverSignature) {\n      orders {\n        relayId\n        item {\n          __typename\n          relayId\n          displayName\n          ... on AssetBundleType {\n            ...bundle_url\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n        id\n      }\n      transaction {\n        blockExplorerLink\n        chain {\n          identifier\n        }\n        transactionHash\n        id\n      }\n    }\n  }\n}\n\nfragment bundle_url on AssetBundleType {\n  slug\n  chain {\n    identifier\n  }\n}\n"
  }
};
})();

(node as any).hash = "8ac8e7c8f0f733760155164869a1ae3b";

export default node;
