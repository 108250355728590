/**
 * @generated SignedSource<<d6f40fb461e6c7e0bfa0115031d586a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChainIdentifier = "AMOY" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SOLANA" | "SOLDEV" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
export type NavSearchDroppingCollectionsQuery$variables = {
  query: string;
};
export type NavSearchDroppingCollectionsQuery$data = {
  readonly searchCollections: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly defaultChain: {
          readonly identifier: ChainIdentifier;
        };
        readonly dropv2: {
          readonly stages: ReadonlyArray<{
            readonly endTime: string;
            readonly startTime: string;
            readonly " $fragmentSpreads": FragmentRefs<"useDropStages">;
          }>;
        } | null;
        readonly isVerified: boolean;
        readonly logo: string | null;
        readonly name: string;
        readonly relayId: string;
        readonly statsV2: {
          readonly floorPrice: {
            readonly symbol: string;
            readonly unit: string;
          } | null;
          readonly totalSupply: number;
        };
        readonly " $fragmentSpreads": FragmentRefs<"collectionRecentViews_data" | "collection_url">;
      } | null;
    } | null>;
  };
};
export type NavSearchDroppingCollectionsQuery = {
  response: NavSearchDroppingCollectionsQuery$data;
  variables: NavSearchDroppingCollectionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 4
  },
  {
    "kind": "Literal",
    "name": "includeOnlyMinting",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logo",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isVerified",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalSupply",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "CollectionStatsV2Type",
  "kind": "LinkedField",
  "name": "statsV2",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "floorPrice",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "symbol",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "defaultChain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startTime",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endTime",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCategory",
  "storageKey": null
},
v13 = {
  "kind": "InlineDataFragmentSpread",
  "name": "collection_url",
  "selections": [
    (v11/*: any*/),
    (v12/*: any*/)
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NavSearchDroppingCollectionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SearchCollectionsTypeConnection",
        "kind": "LinkedField",
        "name": "searchCollections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchCollectionsTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "dropv2",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "stages",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "useDropStages",
                            "selections": [
                              (v9/*: any*/),
                              (v10/*: any*/)
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          },
                          (v9/*: any*/),
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/),
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "collectionRecentViews_data",
                    "selections": [
                      (v5/*: any*/),
                      (v11/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CollectionStatsV2Type",
                        "kind": "LinkedField",
                        "name": "statsV2",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      (v13/*: any*/)
                    ],
                    "args": null,
                    "argumentDefinitions": []
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NavSearchDroppingCollectionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SearchCollectionsTypeConnection",
        "kind": "LinkedField",
        "name": "searchCollections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchCollectionsTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "dropv2",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "stages",
                        "plural": true,
                        "selections": [
                          (v14/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isDropStageV2Type"
                          },
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fc0cc2b34bd65ddc9dd1fa8fe4808c2d",
    "id": null,
    "metadata": {},
    "name": "NavSearchDroppingCollectionsQuery",
    "operationKind": "query",
    "text": "query NavSearchDroppingCollectionsQuery(\n  $query: String!\n) {\n  searchCollections(first: 4, query: $query, includeOnlyMinting: true) {\n    edges {\n      node {\n        logo\n        isVerified\n        name\n        relayId\n        statsV2 {\n          totalSupply\n          floorPrice {\n            unit\n            symbol\n          }\n        }\n        defaultChain {\n          identifier\n        }\n        dropv2 {\n          __typename\n          stages {\n            __typename\n            ...useDropStages\n            startTime\n            endTime\n            id\n          }\n          id\n        }\n        ...collection_url\n        ...collectionRecentViews_data\n        id\n      }\n    }\n  }\n}\n\nfragment collectionRecentViews_data on CollectionType {\n  relayId\n  slug\n  logo\n  isVerified\n  name\n  statsV2 {\n    totalSupply\n  }\n  defaultChain {\n    identifier\n  }\n  ...collection_url\n}\n\nfragment collection_url on CollectionType {\n  slug\n  isCategory\n}\n\nfragment useDropStages on DropStageV2Type {\n  __isDropStageV2Type: __typename\n  startTime\n  endTime\n}\n"
  }
};
})();

(node as any).hash = "986c6aa5c92aa743fd710d029bedd811";

export default node;
