
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import "regenerator-runtime/runtime"; // Needed to prevent regeneratorRuntime is not defined error
import React, { useEffect, useMemo, useState } from "react";
import { useIsHydrated } from "@opensea/ui-kit";
import { NextComponentType, NextPageContext } from "next";
import { AppProps, NextWebVitalsMetric } from "next/app";
import Head from "next/head";
// eslint-disable-next-line no-restricted-imports
import NextRouter, { useRouter as useNextRouter } from "next/router";
import NProgress from "nprogress";
import { useUpdateEffect } from "react-use";
import { OperationType } from "relay-runtime";
import { SEOLanguageLinks } from "@/components/app/SEOLanguageLinks";
import { AppProviders, PageProps } from "@/containers/AppProviders";
import { initWallet } from "@/containers/WalletProvider/wallet";
import { AppContextProps, DEFAULT_APP_CONTEXT } from "@/context/app";
import { DEFAULT_LOCATION_CONTEXT } from "@/context/location";
import { OpenSeaHead } from "@/features/seo";
import { useRouter } from "@/hooks/useRouter";
import { getOrSetDeviceId } from "@/lib/auth/storage";
import { getHydratedChains } from "@/lib/chain/ssr";
import { getToggles } from "@/lib/feature-flags/unleash";
import { getEnvironment } from "@/lib/graphql/environment";
import { handleError, hasGraphQLResponseErrorWithStatus, } from "@/lib/graphql/error";
import { GraphQLRenderer } from "@/lib/graphql/GraphQLRenderer";
import { initDatadogRum, trackAction } from "@/lib/integrations/datadog";
import { getThemeFromCookie } from "@/styles/themes";
import { getSessionId } from "../server/session";
import ErrorPage from "./_error";
import "@/styles/globals.css";
/*
  Note(gabriel): This file has very strong dependencies that impact performance and devX. We should migrate awway from using _app.js for most functionality.
  Please add functionality here very judiciously.
*/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ErrorPageComponentType = NextComponentType<NextPageContext, any, unknown>;
NextRouter.events.on("routeChangeStart", () => NProgress.start());
NextRouter.events.on("routeChangeComplete", () => NProgress.done());
NextRouter.events.on("routeChangeError", () => NProgress.done());
// Init sentry first, so we can track errors
initDatadogRum();
type Props = AppProps<PageProps>;
export const OpenSeaApp = ({ Component, pageProps }: Props) => {
    const { isPageNotFound: isPageInitiallyNotFound, walletState, ssrEnvironment, initialRecords, isAuthenticated, locationContext: staticLocationContext = DEFAULT_LOCATION_CONTEXT, nonce, theme, query, } = pageProps;
    const isHydrated = useIsHydrated();
    const nextRouter = useNextRouter();
    const router = useRouter();
    // TODO(@meemaw): This is hacky way to unblock us to get to ISR. To fix this properly,
    // we would have to do a build for each environment, instead of reusing docker image
    const locationContext = isHydrated ? window.location : staticLocationContext;
    const [state, setState] = useState(DEFAULT_APP_CONTEXT);
    const [isGraphqlResourceNotFound, setIsGraphqlResourceNotFound] = useState(false);
    const isPageNotFound = isGraphqlResourceNotFound || isPageInitiallyNotFound;
    const getComponent = (): React.ComponentType<{
        [key: string]: unknown;
    }> => {
        if (isPageNotFound) {
            return ErrorPage as ErrorPageComponentType;
        }
        return Component;
    };
    const wallet = initWallet(walletState ?? { data: undefined, name: undefined });
    const appContext = useMemo(() => ({
        ...state,
        updateContext: (context: Partial<AppContextProps>) => setState(prevState => ({ ...prevState, ...context }))
    }), [state]);
    useUpdateEffect(() => {
        setIsGraphqlResourceNotFound(false);
    }, [nextRouter.pathname]);
    // This is a temporary workaround to disable prefetches that seem to have started occuring after
    // we introduces middleware. The prefetches are happening for pretty much every page, even though
    // they mostly all use getInitialProps which should not be prefetched. Those requests are thus useless,
    // and just spam the server with requests.
    useEffect(() => {
        nextRouter.prefetch = async () => {
            return;
        };
    }, [nextRouter, router]);
    const environment = typeof window === "undefined"
        ? ssrEnvironment ?? getEnvironment()
        : getEnvironment(initialRecords);
    const toggles = getToggles(pageProps);
    const sessionId = getSessionId(pageProps);
    const chains = getHydratedChains(pageProps);
    Component = getComponent();
    let content: JSX.Element;
    if (query) {
        content = (<GraphQLRenderer<OperationType, {
            variables?: Record<string, unknown>;
            statusCode?: number;
        }> component={Component} handleError={async (error, login, logout) => {
                if (isPageNotFound) {
                    return;
                }
                if (hasGraphQLResponseErrorWithStatus(error, 404)) {
                    setIsGraphqlResourceNotFound(true);
                }
                else {
                    handleError(error, login, logout);
                }
            }} props={isPageNotFound ? { statusCode: 404 } : pageProps} query={query}/>);
    }
    else {
        content = <Component {...pageProps}/>;
    }
    return (<AppProviders appContext={appContext} chains={chains} deviceId={getOrSetDeviceId()} environment={environment} isAuthenticated={Boolean(isAuthenticated)} locationContext={locationContext} nonce={nonce} sessionId={sessionId} theme={theme ?? getThemeFromCookie()} toggles={toggles} wallet={wallet}>
      <Head>
        <meta content="width=device-width,initial-scale=1" name="viewport"/>
        <SEOLanguageLinks origin={locationContext.origin} pathname={nextRouter.asPath}/>
      </Head>
      <OpenSeaHead />
      {content}
    </AppProviders>);
};
export async function reportWebVitals(metric: NextWebVitalsMetric) {
    if (metric.label == "custom") {
        trackAction(metric.name, { value: metric.value });
    }
}
const __Next_Translate__Page__192968b7390__ = OpenSeaApp;

    export default __appWithI18n(__Next_Translate__Page__192968b7390__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  