import type { GetServerSidePropsContext, NextPageContext } from "next"
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment"
import { WALLET_NAME } from "@/constants/wallet"
import { getWallet } from "@/containers/WalletProvider/wallet"
import { trackUser } from "@/lib/analytics/trackUser"
import {
  trackAuthLoginMutation,
  trackAuthLoginMutationError,
  trackAuthLogout,
  trackAuthLogoutMaxAuthenticated,
  trackLegacyAuthLoginMutation,
  trackOs2AuthLoginMutation,
  trackOs2AuthLoginMutationError,
} from "@/lib/auth/analytics"
import { authLoginMutation } from "@/lib/graphql/__generated__/authLoginMutation.graphql"
import { getEnvironment } from "@/lib/graphql/environment"
import { commitMutation } from "@/lib/graphql/fetch"
import { graphql } from "@/lib/graphql/graphql"
import { addressesEqual } from "@/lib/helpers/address"
import Subject from "@/lib/helpers/subject"
import { IS_SERVER } from "../../constants/environment"
import Wallet from "../chain/wallet"
import Cookie from "../cookie"
import { authLoginV2AuthSimplifiedMutation } from "../graphql/__generated__/authLoginV2AuthSimplifiedMutation.graphql"
import { authLogoutMutation } from "../graphql/__generated__/authLogoutMutation.graphql"
import { authRefreshMutation } from "../graphql/__generated__/authRefreshMutation.graphql"
import { captureNoncriticalError } from "../sentry"
import { signChallenge, signOs2Challenge } from "./challenge"
import { MAX_AUTHENTICATED_ACCOUNTS_COUNT } from "./constants"
import { getIsAuthStateSimplificationEnabled } from "./flags"
import { decodeJwtToken, isJwtExpired, matchesJwtSchema } from "./jwt"
import { osSiweAdapter } from "./siwe"
import {
  getAddressFromCookieName,
  getOldestWalletAddress,
  getOrSetDeviceId,
  getSession as getSessionFromStorage,
  getWalletAddressCookie,
  getWalletAddressCookies,
  removeAllSessions,
  removeSession,
  setSession,
} from "./storage"
import { JWTPayload, Session } from "./types"

export type LoggedInAccount = {
  address?: string
  isEmployee?: boolean
  moonpayKycStatus?: string | null
  moonpayKycRejectType?: string | null
}

const loginSubject = new Subject<LoggedInAccount>()

const UNSAFE_getActiveSession = (): Session | undefined => {
  if (IS_SERVER) {
    return undefined
  }
  const wallet = getWallet()
  return getValidSession(wallet.activeAccount?.address)
}

const doesJwtBelongToAccount = (address: string, payload: JWTPayload) => {
  return addressesEqual(address, payload.address)
}

const getValidSession = (
  address: string | undefined,
  context?: NextPageContext | GetServerSidePropsContext,
): Session | undefined => {
  if (!address) {
    return undefined
  }

  const session = getSessionFromStorage(address, context)
  if (!session) {
    return undefined
  }

  const { payload } = session
  if (isJwtExpired(payload) || !matchesJwtSchema(payload)) {
    removeSession(address)
    return undefined
  }

  if (doesJwtBelongToAccount(address, payload)) {
    return session
  }

  return undefined
}

const getAuthenticatedAccountsCount = (): number => {
  // Returns the number of authenticated accounts, which is the number of
  // wallet address cookies present in the browser.
  const walletAddressCookies = getWalletAddressCookies()
  return walletAddressCookies.length
}

const getIsAuthenticated = (
  address: string | undefined,
  context?: NextPageContext | GetServerSidePropsContext,
): boolean => {
  if (!address) {
    return false
  }
  const isAuthStateSimplificationEnabled = getIsAuthStateSimplificationEnabled()

  if (isAuthStateSimplificationEnabled) {
    const cookie = getWalletAddressCookie(address)
    const exists = cookie.get(context)
    return Boolean(exists)
  }

  return Boolean(getValidSession(address))
}

const setLogoutCookie = (value: boolean) => {
  const loggedOutCookie = new Cookie<boolean | undefined>("opensea_logged_out")
  loggedOutCookie.set(value, { secure: true, sameSite: "Lax", expires: 500 }) // really long expiration date
}

interface LoginInput {
  activeAccount: Wallet["activeAccount"]
  getProviderOrRedirect: Wallet["getProviderOrRedirect"]
  ensureLoginCompatibleNetwork: Wallet["ensureLoginCompatibleNetwork"]
  sign: Wallet["sign"]
  setAuthenticatedAccount: Wallet["setAuthenticatedAccount"]
  privyAccessToken?: string
}

const handleReachedMaxAuthenticatedAccounts = async () => {
  const originalAuthenticatedAccountsCount = getAuthenticatedAccountsCount()
  while (getAuthenticatedAccountsCount() >= MAX_AUTHENTICATED_ACCOUNTS_COUNT) {
    const addressToLogout = getOldestWalletAddress()

    if (addressToLogout) {
      await logoutAddress(addressToLogout)
    } else {
      // If we can't find an address to logout, break out of the loop
      break
    }
  }

  if (originalAuthenticatedAccountsCount >= MAX_AUTHENTICATED_ACCOUNTS_COUNT) {
    trackAuthLogoutMaxAuthenticated({
      count: originalAuthenticatedAccountsCount,
      success:
        getAuthenticatedAccountsCount() < MAX_AUTHENTICATED_ACCOUNTS_COUNT,
    })
  }
}

const login = async ({
  activeAccount,
  getProviderOrRedirect,
  ensureLoginCompatibleNetwork,
  sign,
  setAuthenticatedAccount,
}: LoginInput): Promise<LoggedInAccount | undefined> => {
  const provider = await getProviderOrRedirect()
  const accountKey = activeAccount && { address: activeAccount.address }
  if (!activeAccount || !accountKey || !provider) {
    console.info("No active account. Aborting login")
    return undefined
  }
  const isAuthenticated = await getIsAuthenticated(activeAccount.address)
  if (isAuthenticated) {
    setLogoutCookie(false)
    return activeAccount
  }
  if (loginSubject.isPending) {
    return loginSubject.observe()
  }

  const { address } = accountKey

  loginSubject.begin()

  await ensureLoginCompatibleNetwork()

  try {
    const environment = getEnvironment()
    const isAuthStateSimplificationEnabled =
      getIsAuthStateSimplificationEnabled()
    const signedMessage = await signChallenge(sign, address)

    let token
    let account
    if (isAuthStateSimplificationEnabled) {
      await handleReachedMaxAuthenticatedAccounts()
      const { AuthTypeV2 } =
        await commitMutation<authLoginV2AuthSimplifiedMutation>(
          environment,
          graphql`
            mutation authLoginV2AuthSimplifiedMutation(
              $address: AddressScalar!
              $message: String!
              $deviceId: String!
              $signature: String!
              $chain: ChainScalar
            ) {
              AuthTypeV2 {
                webLoginV2(
                  address: $address
                  deviceId: $deviceId
                  message: $message
                  signature: $signature
                  chain: $chain
                ) {
                  address
                  isEmployee
                }
              }
            }
          `,
          {
            ...signedMessage,
            chain: await provider.getChain(),
            deviceId: getOrSetDeviceId(),
          },
        )
      token = ""
      account = AuthTypeV2.webLoginV2
      trackAuthLoginMutation()
    } else {
      const { auth } = await commitMutation<authLoginMutation>(
        environment,
        graphql`
          mutation authLoginMutation(
            $address: AddressScalar!
            $message: String!
            $signature: String!
            $chain: ChainScalar
          ) {
            auth {
              webLogin(
                address: $address
                message: $message
                signature: $signature
                chain: $chain
              ) {
                token
                account {
                  address
                  isEmployee
                  moonpayKycStatus
                  moonpayKycRejectType
                }
              }
            }
          }
        `,
        { ...signedMessage, chain: await provider.getChain() },
      )
      token = auth.webLogin.token
      account = auth.webLogin.account
      trackLegacyAuthLoginMutation()
    }
    setAuthenticatedAccount(account)
    trackUser(account)
    // TODO: ensure wallet cookie present if auth simplification enabled

    if (!getIsAuthStateSimplificationEnabled()) {
      await setSession(address, {
        token,
        payload: decodeJwtToken(token),
      })
    }
    loginSubject.resolve(account)
    setLogoutCookie(false)
    return account
  } catch (error) {
    trackAuthLoginMutationError({
      authSimplificationEnabled: getIsAuthStateSimplificationEnabled(),
    })
    loginSubject.reject(error)
    throw error
  }
}

const os2Login = async ({
  getProviderOrRedirect,
  activeAccount,
  sign,
  privyAccessToken,
}: LoginInput) => {
  const provider = await getProviderOrRedirect()
  const accountKey = activeAccount && { address: activeAccount.address }
  if (!activeAccount || !accountKey || !provider) {
    console.info("No active account. Aborting login")
    return undefined
  }

  const { address } = accountKey

  trackOs2AuthLoginMutation()

  // Attempt token exchange first for embedded wallet
  if (provider.getName() === WALLET_NAME.OpenSeaWallet && privyAccessToken) {
    try {
      await osSiweAdapter.exchangeToken({ token: privyAccessToken })
      return
    } catch (error) {
      trackOs2AuthLoginMutationError()
      console.error("Failed to exchange token", error)
    }
  }

  const signedMessage = await signOs2Challenge(sign, address)
  try {
    await osSiweAdapter.verify(signedMessage)
  } catch (error) {
    trackOs2AuthLoginMutationError()
    throw error
  }
}

let refreshTimeout: NodeJS.Timeout | undefined = undefined

const refresh = async (): Promise<
  { isEmployee: boolean } | undefined | null
> => {
  if (!getIsAuthStateSimplificationEnabled()) {
    return
  }
  const environment = getEnvironment()
  const wallet = getWallet()
  const address = wallet.activeAccount?.address
  if (!address) {
    return
  }

  clearTimeout(refreshTimeout)

  const { AuthTypeV2 } = await commitMutation<authRefreshMutation>(
    environment,
    graphql`
      mutation authRefreshMutation(
        $address: AddressScalar!
        $deviceId: String!
      ) {
        AuthTypeV2 {
          webRefresh(address: $address, deviceId: $deviceId) {
            isEmployee
          }
        }
      }
    `,
    { address, deviceId: getOrSetDeviceId() },
  )

  refreshTimeout = setTimeout(
    async () => {
      try {
        refresh()
      } catch (ex) {
        captureNoncriticalError(ex)
      }
    },
    // every 9 minutes
    1000 * 60 * 9,
  )

  return AuthTypeV2.webRefresh
}

const logoutAddress = async (
  address: string,
  environment?: RelayModernEnvironment,
) => {
  const environmentToUse = environment ?? getEnvironment()
  await commitMutation<authLogoutMutation>(
    environmentToUse,
    graphql`
      mutation authLogoutMutation(
        $address: AddressScalar!
        $deviceId: String!
      ) {
        AuthTypeV2 {
          webLogout(address: $address, deviceId: $deviceId)
        }
      }
    `,
    { address, deviceId: getOrSetDeviceId() },
  )
}

const logoutAll = async () => {
  if (IS_SERVER) {
    return
  }

  if (getIsAuthStateSimplificationEnabled()) {
    const environment = getEnvironment()
    const walletAddressCookies = getWalletAddressCookies()

    walletAddressCookies.forEach(async cookieName => {
      const address = getAddressFromCookieName(cookieName)
      await logoutAddress(address, environment)
    })
    trackAuthLogout({
      count: walletAddressCookies.length,
    })
  }
  console.warn("Logging out")
  removeAllSessions()
  setLogoutCookie(true)
  clearTimeout(refreshTimeout)
}

export default {
  UNSAFE_getActiveSession,
  getValidSession,
  getIsAuthenticated,
  login,
  logout: logoutAll,
  signChallenge,
  setLogoutCookie,
  refresh,
  os2Login,
}
