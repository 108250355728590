/**
 * @generated SignedSource<<50ea128af3fbcf153497c49de019ece8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SOLANA" | "SOLDEV" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WalletFunds_data$data = {
  readonly wallet: {
    readonly funds: ReadonlyArray<{
      readonly chain: ChainIdentifier;
      readonly symbol: string;
      readonly " $fragmentSpreads": FragmentRefs<"FundListItem_data" | "utils_calculateFundsBalance">;
    }>;
  };
  readonly " $fragmentType": "WalletFunds_data";
};
export type WalletFunds_data$key = {
  readonly " $data"?: WalletFunds_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"WalletFunds_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "address"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./WalletFunds_data_query.graphql')
    }
  },
  "name": "WalletFunds_data",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "address",
          "variableName": "address"
        }
      ],
      "concreteType": "WalletType",
      "kind": "LinkedField",
      "name": "wallet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WalletFundsType",
          "kind": "LinkedField",
          "name": "funds",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "symbol",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "chain",
              "storageKey": null
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "utils_calculateFundsBalance",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "usdPrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quantity",
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "FundListItem_data"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "fc33a500cb25679ca71168358b6178c5";

export default node;
