import { ErrorCode } from "@ethersproject/logger"
import { ethers } from "ethers"
import { Buffer } from "safe-buffer"
import { WALLET_NAME } from "@/constants/wallet"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { BigNumber } from "@/lib/helpers/numberUtils"
import type { Promiseable } from "@/lib/helpers/promise"
import { captureNoncriticalError, CaptureExceptionArgs } from "@/lib/sentry"
import type { Address, WalletAccountKey } from "./chain"
import type { SignOptions } from "./wallet"

export type Transaction = {
  source?: Address
  destination?: Address
  value?: BigNumber
  data?: string
}
export type TransactionResponse = {
  hash: TransactionId
  evmResponse?: ethers.providers.TransactionResponse
}

export type TransactionOptions = {
  transactAtBlockTimestamp?: number
  isContractDeploy?: boolean
}

export type TransactionId = string

export const USER_REJECTED_REQUEST_ERROR_CODE = 4001

type BaseErrorFields = {
  name?: string
  message?: string
  code?: number | string
}

type PotentialJsonRpcError = BaseErrorFields & {
  data?: {
    originalError?: Error & BaseErrorFields & { data?: string }
  }
  error?: {
    data?: {
      originalError?: Error & BaseErrorFields & { data?: string }
    }
  } & BaseErrorFields
}

const EXECUTION_REVERTED_ERROR_MESSAGE =
  "Execution reverted. Please reach out to the collection owner to troubleshoot."

const REJECTED_WALLET_REQUEST_ERROR_MESSAGE =
  "You rejected the request in your wallet."
const PENDING_WALLET_REQUEST_ERROR_MESSAGE =
  "There is a pending request in your wallet"
const REJECTION_MESSAGES = [
  "User denied message signature", // MM Typed message rejection message
  "User rejected the request", // MM General rejection message
  "User rejected the request.", // MM General rejection message
  "cancelled", // trustwallet
  "User disapproved requested methods", // walletconnect
  "user reject this request", // https://opensea.sentry.io/issues/4165448375/?project=277230
]
const PENDING_MESSAGES = [
  "Please accept or reject the pending network switch request",
]

export class JsonRpcError extends Error {
  error: PotentialJsonRpcError
  code?: string

  private static ERROR_CODE_MAP: Record<string, string> = {
    "-32000": "Missing or invalid parameters",
    "-32015": EXECUTION_REVERTED_ERROR_MESSAGE,
    "-32601": "The method does not exist / is not available.",
    "-32603": EXECUTION_REVERTED_ERROR_MESSAGE,
    "4001": REJECTED_WALLET_REQUEST_ERROR_MESSAGE,
    "4100": "The requested method and/or account has not been authorized.",
    "4902": "Requested chain has not been added.",

    // Ethers-specific errors
    [ErrorCode.UNKNOWN_ERROR]: "An unknown error occurred.",
    [ErrorCode.NOT_IMPLEMENTED]: "The requested method is not implemented.",
    [ErrorCode.UNSUPPORTED_OPERATION]:
      "The requested operation is not supported.",
    [ErrorCode.NETWORK_ERROR]: "A network error occurred.",
    [ErrorCode.INSUFFICIENT_FUNDS]: "Insufficient funds",
    [ErrorCode.NONCE_EXPIRED]: "The transaction nonce has already been used.",
    [ErrorCode.REPLACEMENT_UNDERPRICED]: "The replacement fee is too low.",
    [ErrorCode.UNPREDICTABLE_GAS_LIMIT]: "The gas required exceeds the limit.",
    [ErrorCode.CALL_EXCEPTION]: "A call exception occurred.",
    // For some reason, not in the ErrorCode enum
    ACTION_REJECTED: "You rejected the request in your wallet.",
  }

  constructor(error: PotentialJsonRpcError) {
    super()
    this.error = error
    this.code = (error.error?.code || error.code)?.toString()
    // Metamask error message suddenly started returning -32603 error code as a typed message rejection. Unfortunately this code
    // is also used for general execution reverted so this is just a stop gap.
    if (
      error.message &&
      REJECTION_MESSAGES.find(message => error.message?.includes(message))
    ) {
      this.message = REJECTED_WALLET_REQUEST_ERROR_MESSAGE
    } else if (
      error.message &&
      PENDING_MESSAGES.find(message => error.message?.includes(message))
    ) {
      this.message = PENDING_WALLET_REQUEST_ERROR_MESSAGE
    } else if (this.code) {
      this.message =
        JsonRpcError.ERROR_CODE_MAP[this.code.toString()] ||
        "An unexpected error occurred."
    }
  }

  static isUserCancellationOrPending(error: PotentialJsonRpcError): boolean {
    const code = (error.error?.code || error.code)?.toString()
    return (
      (error.message &&
        !!REJECTION_MESSAGES.find(
          message => error.message?.includes(message),
        )) ||
      (error.message &&
        !!PENDING_MESSAGES.find(message => error.message?.includes(message))) ||
      code === "ACTION_REJECTED" ||
      code === "4001"
    )
  }

  static isWalletRequestConnectionResetError(
    error: PotentialJsonRpcError,
  ): boolean {
    return (
      error.message === "Connection request reset. Please try again." ||
      error.message === "User denied account authorization"
    )
  }

  static isJsonRpcError(error: PotentialJsonRpcError): boolean {
    return Boolean(error.error?.code || error.code)
  }

  static isExecutionRevertedError(error: PotentialJsonRpcError): boolean {
    return (
      (error instanceof JsonRpcError ? error : new JsonRpcError(error))
        .message === EXECUTION_REVERTED_ERROR_MESSAGE
    )
  }

  static captureOriginalError(
    error: PotentialJsonRpcError,
    captureExceptionArgs?: Omit<CaptureExceptionArgs, "level">,
  ): void {
    if (JsonRpcError.isJsonRpcError(error)) {
      const originalError = error.error?.data?.originalError
        ? error.error.data.originalError
        : error.error
        ? error.error
        : error

      const errorToCapture = new Error(
        `${originalError.message} (code: ${
          originalError.code
        }, data: ${JSON.stringify(originalError.data)})`,
      )
      captureNoncriticalError(errorToCapture, captureExceptionArgs)
    }
  }
}

export type OnChainChangeHandler = (
  chainIdentifier: ChainIdentifier | null | undefined,
) => unknown

export default abstract class Provider {
  // Some providers (e.g. Phantom) have a different locking concept, which doesn't actually
  // disconnects the user and thus does not emit any event. For such providers, "onAccountsChange"
  // event wont be triggered, and should be handled differently.
  public hasNoLockingConcept() {
    return false
  }

  public abstract connect(): Promise<WalletAccountKey[]>

  public abstract disconnect(): Promiseable<unknown>

  public abstract getAccounts(): Promise<WalletAccountKey[]>

  public abstract getName(): WALLET_NAME

  public getMetadata(): string | undefined {
    return undefined
  }

  public abstract onAccountsChange(
    handler: (accounts: WalletAccountKey[]) => unknown,
  ): () => unknown

  public abstract onChainChange(handler: OnChainChangeHandler): () => void

  public abstract sign(
    message: string | Buffer,
    address: string,
    options?: SignOptions,
  ): Promiseable<string>

  public abstract signTypedData(
    message: string | Buffer,
    address: string,
    options?: SignOptions,
  ): Promiseable<string>

  public abstract transact(
    transaction: Transaction,
    transactionOptions?: TransactionOptions,
  ): Promise<TransactionResponse>

  public abstract estimateGas(transaction: Transaction): Promise<string>

  public abstract getNativeCurrencyBalance(address: string): Promise<string>

  public abstract getChain(): Promiseable<ChainIdentifier | null | undefined>

  public abstract getChainId(): Promiseable<number | undefined>

  public abstract getTransactionCount(address: string): Promiseable<number>
}
