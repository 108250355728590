import React, { useMemo } from "react"
import clsx from "clsx"
import { isArray, noop } from "lodash"
import { rgba } from "polished"
import styled, { css } from "styled-components"
import {
  Popover,
  PopoverContentProps,
  PopoverProps,
  POPOVER_PLACEMENT,
} from "../Popover"
import { DropdownDivider, DropdownItem, DropdownList } from "./elements"
import "tippy.js/animations/shift-away.css"

export const DROPDOWN_PLACEMENT = POPOVER_PLACEMENT

type CloseDropdown = PopoverContentProps["close"]

export type RenderItemProps<T> = {
  item: T
  close: CloseDropdown
  Item: typeof DropdownItem
  onClick?: () => void
  onBlur?: (event: { relatedTarget: EventTarget | null }) => void
}

export type RenderItem<T> = (props: RenderItemProps<T>) => React.ReactNode

type DropdownBaseProps = Omit<PopoverProps, "content"> &
  Pick<
    React.CSSProperties,
    "width" | "minWidth" | "maxHeight" | "overflowY"
  > & {
    /**
     * Using this prop instead of forwarding the ref since forwardRef cannot
     * preserve the generic item type (T) on DropdownItemProps
     */
    popoverRef?: React.Ref<HTMLDivElement>
  }

export type DropdownItemProps<T> = DropdownBaseProps & {
  items: ReadonlyArray<T> | ReadonlyArray<ReadonlyArray<T>>
  renderItem: RenderItem<T>
  content?: undefined
  dropdownRef?: React.RefObject<HTMLUListElement>
}

export type RenderDropdownContentProps = {
  close: CloseDropdown
  Item: typeof DropdownItem
  List: typeof DropdownList
  Divider?: typeof DropdownDivider
}

export type DropdownContentProps = DropdownBaseProps & {
  content: (props: RenderDropdownContentProps) => React.ReactNode
  items?: undefined
  renderItem?: undefined
  dropdownRef?: undefined
}

export type DropdownProps<T> = DropdownItemProps<T> | DropdownContentProps

const DropdownBase = <T,>({
  appendTo = "parent",
  minWidth,
  width,
  children,
  delay,
  placement = "bottom-start",
  disabled,
  className,
  offset,
  maxWidth,
  maxHeight,
  hideOnClick,
  hideOnScroll,
  trigger = "click",
  visible,
  popperOptions,
  lazy,
  onTrigger,
  onUntrigger,
  onHide,
  onHidden,
  animation,
  getReferenceClientRect,
  zIndex,
  variant,
  borderRadius,
  matchReferenceWidth,
  popoverRef,
  onShown,
  overflowY,
  onMount,
  ...rest
}: DropdownProps<T>) => {
  const { items, dropdownRef } = rest
  const sections: ReadonlyArray<ReadonlyArray<T>> = useMemo(() => {
    if (isArray(items?.[0])) {
      return items as ReadonlyArray<ReadonlyArray<T>>
    } else if (items) {
      return [items as ReadonlyArray<T>]
    } else {
      return []
    }
  }, [items])
  const renderContent = ({ close }: PopoverContentProps) => {
    const { renderItem, content } = rest
    if (content) {
      return content({
        close,
        Divider: DropdownDivider,
        Item: DropdownItem,
        List: DropdownList,
      })
    }
    return (
      <DropdownList ref={dropdownRef} style={{ width }}>
        {sections.map((items, i) => {
          return (
            <>
              {i > 0 && <DropdownDivider />}
              {items.map(item =>
                renderItem({
                  close,
                  item,
                  Item: DropdownItem,
                }),
              )}
            </>
          )
        })}
      </DropdownList>
    )
  }

  return (
    <StyledPopover
      $maxHeight={maxHeight}
      $minWidth={minWidth}
      $overflowY={overflowY}
      animation={animation}
      appendTo={appendTo}
      arrow={false}
      borderRadius={borderRadius}
      className={clsx("Dropdown", className)}
      content={renderContent}
      delay={delay}
      disabled={disabled}
      getReferenceClientRect={getReferenceClientRect}
      hideOnClick={hideOnClick}
      hideOnScroll={hideOnScroll}
      lazy={lazy}
      matchReferenceWidth={matchReferenceWidth}
      maxWidth={maxWidth}
      offset={offset}
      placement={placement}
      popperOptions={popperOptions}
      ref={popoverRef}
      touch
      trigger={trigger}
      variant={variant}
      visible={visible}
      zIndex={zIndex}
      onHidden={onHidden || noop}
      onHide={onHide || noop}
      onMount={onMount}
      onShown={onShown}
      onTrigger={onTrigger}
      onUntrigger={onUntrigger || noop}
    >
      {children}
    </StyledPopover>
  )
}

export const Dropdown = Object.assign(DropdownBase, {
  Item: DropdownItem,
  List: DropdownList,
})

const StyledPopover = styled(Popover)<
  DropdownProps<unknown> & {
    $minWidth: DropdownBaseProps["minWidth"]
    $maxHeight: DropdownBaseProps["maxHeight"]
    $overflowY: DropdownBaseProps["overflowY"]
  }
>`
  &.Dropdown {
    background-color: ${props =>
      props.theme.colors.components.elevation.level3.background};
    border-radius: ${props => props.theme.borderRadius.list};
    box-shadow: ${props =>
      props.theme.colors.components.elevation.level3.shadow};
    color: ${props => props.theme.colors.text.primary};
    max-height: ${props => props.$maxHeight ?? 350}px;
    max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : "initial")};
    min-width: ${props => props.$minWidth ?? 220}px;

    // To always show scrollbar, change overflow-y to "scroll"
    // and conditionally show the ::-webkit-scrollbar to show on iOS
    // https://stackoverflow.com/a/59436086
    overflow-y: ${props => props.$overflowY || "auto"};
    ${props =>
      props.$overflowY === "scroll"
        ? css`
            ::-webkit-scrollbar {
              // this width takes into consideration the 2px
              // transparent border hack below, so it is actually 8px
              width: 12px;
            }

            ::-webkit-scrollbar-thumb {
              border-radius: 12px;
              background-color: ${props =>
                props.theme.type === "dark"
                  ? rgba(props.theme.colors.white, 0.12)
                  : props.theme.colors.components.border.level2};
              // background-clip and transparent border help hack
              // the illusion that there is a margin from the edge
              background-clip: content-box;
              border: 2px solid transparent;
            }
          `
        : null}

    .tippy-content {
      padding: 0;
      text-align: initial;
    }
  }
`
