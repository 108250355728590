/**
 * @generated SignedSource<<15bff3011e78ed1528cea137c346c0e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WalletPopoverDataPollerClosedQuery$variables = {
  address: string;
  wrappedCurrencyChain: | "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "MUMBAI" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "GOERLI" | "SOLDEV" | "ARBITRUM_GOERLI" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "OPTIMISM_GOERLI" | "SEPOLIA" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "GUNZILLA_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "ZORA" | "ZORA_TESTNET" | "ZORA_SEPOLIA" | "B3_SEPOLIA" | "%future added value";
  wrappedCurrencySymbol: string;
};
export type WalletPopoverDataPollerClosedQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"WalletAndAccountButtonFundsDisplay_data">;
};
export type WalletPopoverDataPollerClosedQuery = {
  response: WalletPopoverDataPollerClosedQuery$data;
  variables: WalletPopoverDataPollerClosedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "address"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "wrappedCurrencyChain"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "wrappedCurrencySymbol"
},
v3 = {
  "kind": "Variable",
  "name": "address",
  "variableName": "address"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WalletPopoverDataPollerClosedQuery",
    "selections": [
      {
        "args": [
          (v3/*: any*/),
          {
            "kind": "Variable",
            "name": "wrappedCurrencyChain",
            "variableName": "wrappedCurrencyChain"
          },
          {
            "kind": "Variable",
            "name": "wrappedCurrencySymbol",
            "variableName": "wrappedCurrencySymbol"
          }
        ],
        "kind": "FragmentSpread",
        "name": "WalletAndAccountButtonFundsDisplay_data"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "WalletPopoverDataPollerClosedQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v3/*: any*/)
        ],
        "concreteType": "WalletType",
        "kind": "LinkedField",
        "name": "wallet",
        "plural": false,
        "selections": [
          {
            "alias": "wrappedCurrencyFunds",
            "args": [
              {
                "kind": "Variable",
                "name": "chain",
                "variableName": "wrappedCurrencyChain"
              },
              {
                "kind": "Variable",
                "name": "symbol",
                "variableName": "wrappedCurrencySymbol"
              }
            ],
            "concreteType": "WalletFundsType",
            "kind": "LinkedField",
            "name": "fundsOf",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quantity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "symbol",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f127bbbaeb54c2ad9d011c8fa7a1848b",
    "id": null,
    "metadata": {},
    "name": "WalletPopoverDataPollerClosedQuery",
    "operationKind": "query",
    "text": "query WalletPopoverDataPollerClosedQuery(\n  $address: AddressScalar!\n  $wrappedCurrencySymbol: String!\n  $wrappedCurrencyChain: ChainScalar!\n) {\n  ...WalletAndAccountButtonFundsDisplay_data_p0g3U\n}\n\nfragment FundsDisplay_walletFunds on WalletFundsType {\n  symbol\n  quantity\n}\n\nfragment WalletAndAccountButtonFundsDisplay_data_p0g3U on Query {\n  wallet(address: $address) {\n    wrappedCurrencyFunds: fundsOf(symbol: $wrappedCurrencySymbol, chain: $wrappedCurrencyChain) {\n      quantity\n      symbol\n      ...FundsDisplay_walletFunds\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "00b7aa817f406616efce359496c6fa1d";

export default node;
