import React from "react"

type Props = {
  className?: string
  width?: number
  fill?: string
}

export const B3Logo = ({ className, fill = "none", width = 20 }: Props) => (
  <svg
    className={className}
    fill={fill}
    height={width}
    viewBox="0 0 232 233"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="color(display-p3 .2 .4078 .9373)"
      d="M15.914 0h170.727c3.089 0 5.59 2.443 5.59 5.46v78.127c0 4.878-2.91 9.306-7.444 11.334l-21.504 9.64a2.893 2.893 0 0 0-1.727 2.631c0 1.12.655 2.13 1.69 2.61l21.698 10.076c4.451 2.065 7.287 6.448 7.287 11.268v84.387c0 3.017-2.501 5.46-5.59 5.46H15.914C7.124 220.993 0 214.036 0 205.45V15.543C0 6.957 7.124 0 15.914 0zm92.599 147.328c4.354 0 7.458-.574 9.304-1.715 2.01-1.149 3.015-3.272 3.015-6.383v-.494c0-3.272-1.087-5.569-3.268-6.878-2.01-1.308-5.448-1.962-10.309-1.962H75.426v17.432h33.087zm-1.504-59.67c7.712 0 11.568-2.704 11.568-8.105v-1.229c0-3.111-1.005-5.241-3.015-6.383-1.846-1.308-4.943-1.963-9.305-1.963H75.433v17.68h31.584-.008zm99.692 41.503h70.357c0 5.569 1.005 9.415 3.015 11.545 2.181 2.13 5.277 3.191 9.304 3.191 6.871 0 10.31-3.846 10.31-11.544 0-3.766-1.176-6.463-3.521-8.106-2.181-1.636-5.531-2.457-10.056-2.457h-38.722V93.307l59.586-14.489H206.701c-2.798 0-5.069-2.217-5.069-4.95V4.136c0-2.282 1.898-4.136 4.236-4.136h164.95c8.791 0 15.914 6.957 15.914 15.543v55.73c0 6.295-4.429 11.77-10.696 13.23l-65.288 15.194 3.015.495h62.31c7.689 0 13.927 6.092 13.927 13.601v91.664c0 8.586-7.124 15.543-15.914 15.543H205.868c-2.338 0-4.236-1.854-4.236-4.136v-82.752c0-2.733 2.271-4.951 5.069-4.951z"
    />
  </svg>
)
