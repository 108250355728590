import { ChainIdentifier } from "@/hooks/useChains/types"

const MOONPAY_CURRENCY_CODES: Partial<
  Record<ChainIdentifier, Record<string, string>>
> = {
  ETHEREUM: {
    ETH: "eth",
    USDC: "usdc",
    DAI: "dai",
    WETH: "weth",
    SAND: "sand",
    MANA: "mana",
  },
  MATIC: {
    MATIC: "matic_polygon",
    ETH: "eth_polygon",
    USDC: "usdc_polygon",
  },
  ARBITRUM: {
    ETH: "eth_arbitrum",
    USDC: "usdc_arbitrum",
  },
  OPTIMISM: {
    ETH: "eth_optimism",
    USDC: "usdc_optimism",
  },
  BASE: {
    ETH: "eth_base",
    USDC: "usdc_base",
  },
  AVALANCHE: {
    AVAX: "avax_cchain",
  },
  KLAYTN: {
    KLAY: "klay",
  },
}

export const getMoonPayCurrencyCode = (
  symbol: string,
  chain: ChainIdentifier,
): string | undefined => {
  return MOONPAY_CURRENCY_CODES[chain]?.[symbol]
}

const DEFAULT_MOONPAY_CURRENCY_CODES: Partial<Record<ChainIdentifier, string>> =
  {
    ETHEREUM: "eth",
    MATIC: "matic_polygon",
    ARBITRUM: "eth_arbitrum",
    OPTIMISM: "eth_optimism",
    BASE: "eth_base",
    AVALANCHE: "avax_cchain",
    KLAYTN: "klay",
  }

export const getDefaultMoonPayCurrencyCode = (
  chain: ChainIdentifier,
): string | undefined => {
  return DEFAULT_MOONPAY_CURRENCY_CODES[chain]
}
