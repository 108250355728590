import Wallet from "@/lib/chain/wallet"
import type { challengeLoginMessageQuery } from "@/lib/graphql/__generated__/challengeLoginMessageQuery.graphql"
import { fetch, graphql } from "@/lib/graphql/graphql"
import { osSiweAdapter } from "./siwe"
import type { SignedMessage } from "./types"

const fetchLoginMessage = async (address: string): Promise<string> => {
  const [
    {
      auth: { loginMessage },
    },
  ] = await fetch<challengeLoginMessageQuery>(
    graphql`
      query challengeLoginMessageQuery($address: AddressScalar!) {
        auth {
          loginMessage(address: $address)
        }
      }
    `,
    { address },
  )
  return loginMessage
}

export const signChallenge = async (
  sign: Wallet["sign"],
  address: string,
): Promise<SignedMessage> => {
  const message = await fetchLoginMessage(address)
  const signature = await sign(message)
  return { address, message, signature }
}

const fetchOs2LoginMessage = async (address: string): Promise<string> => {
  const nonce = await osSiweAdapter.getNonce()

  return osSiweAdapter.createMessage({ nonce, address })
}

export const signOs2Challenge = async (
  sign: Wallet["sign"],
  address: string,
): Promise<SignedMessage> => {
  const message = await fetchOs2LoginMessage(address)
  const signature = await sign(message)
  return { address, message, signature }
}
