/* eslint-disable tailwindcss/no-custom-classname */
import React, { useEffect, useState } from "react"
import { useTheme as useNextTheme } from "@opensea/next-themes"
import {
  Icon,
  UnstyledButton,
  Switch,
  Label,
  CenterAligned,
  Flex,
} from "@opensea/ui-kit"
import styled from "styled-components"
import { OPENSEA_PRO_URL } from "@/constants/urls"
import { Z_INDEX } from "@/constants/zIndex"
import {
  useActiveAccount,
  useWallet,
} from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { useTheme } from "@/design-system/Context"
import { SettingsMenu } from "@/features/settings/components/SettingsMenu"
import {
  useIsMintYourOwnEnabled,
  useOSProGlobalLinkingExperience,
} from "@/hooks/useFlag"
import { useRouter } from "@/hooks/useRouter/useRouter"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { getShortLocaleLabel } from "@/lib/helpers/i18n"
import { persistThemeSelection } from "@/styles/themes"
import { NavItem, NavItemProps } from "./NavItem.react"
import navMenus, { SocialsItem } from "./navMenus"

type Props = {
  onClose: () => unknown
}

type View = "main" | "accountSettings" | keyof typeof navMenus

export const NavMobile = ({ onClose }: Props) => {
  const t = useTranslate("components")
  const isMintYourOwnEnabled = useIsMintYourOwnEnabled()
  const [view, setView] = useState<View>("main")
  const activeAccount = useActiveAccount()
  const { logout } = useWallet()
  const { showSuccessMessage } = useToasts()
  const router = useRouter()

  useEffect(() => {
    const unsub = router.onChange(onClose)
    return () => {
      unsub()
    }
  }, [onClose, router])

  const ViewNav = ({
    children,
    view,
    onClick,
    ...navItemProps
  }: {
    children: React.ReactNode
    view?: View
    onClick?: () => unknown
  } & Pick<
    NavItemProps,
    "href" | "icon" | "iconTitle" | "iconVariant" | "image"
  >) => (
    <NavItem
      {...navItemProps}
      onClick={onClick || (view && (() => setView(view)))}
    >
      {children}
      {view ? (
        <Icon className="NavMobile--submenu-icon" value="chevron_right" />
      ) : null}
    </NavItem>
  )

  const isOSProLinkEnabled = useOSProGlobalLinkingExperience() !== "disabled"

  const { theme } = useTheme()
  const { toggleTheme } = useNextTheme()
  const isDarkMode = theme === "dark"

  return (
    <DivContainer className="w-screen lg:w-[initial]" data-testid="NavMobile">
      {view === "main" ? (
        <ul className="NavMobile--menu">
          <ViewNav icon="calendar_month" view="drops">
            {t("nav.drops", "Drops")}
          </ViewNav>
          <ViewNav icon="bar_chart" view="stats">
            {t("nav.stats", "Stats")}
          </ViewNav>
          <ViewNav icon="library_books" view="resources">
            {t("nav.resources", "Resources")}
          </ViewNav>
          <ViewNav
            href={
              isMintYourOwnEnabled ? "/studio/create?ref=nav" : "/asset/create"
            }
            icon="edit"
          >
            {t("nav.create", "Create")}
          </ViewNav>
          {isOSProLinkEnabled && (
            <ViewNav
              href={OPENSEA_PRO_URL}
              image="/static/images/icons/opensea-pro"
            >
              {t("nav.openseaPro", "OpenSea Pro")}
            </ViewNav>
          )}
          {activeAccount && (
            <ViewNav icon="account_circle" view="account">
              {t("nav.accountLink", "Account")}
            </ViewNav>
          )}
          <ViewNav icon="language" view="language">
            <Flex className="w-full">
              {t("nav.languageSelectorLabel", "Language")}
              {router.locale && (
                <Block marginLeft="auto">
                  {getShortLocaleLabel(router.locale)}
                </Block>
              )}
            </Flex>
          </ViewNav>
          <ViewNav
            icon="mode_night"
            onClick={() => {
              persistThemeSelection(toggleTheme())
            }}
          >
            <Flex asChild className="NavMobile--toggle-container">
              <span>
                <Label htmlFor="night-mode-toggle">
                  {t("nav.themeNightToggleLabel", "Night mode")}
                </Label>
                <Switch checked={isDarkMode} id="night-mode-toggle" />
              </span>
            </Flex>
          </ViewNav>
        </ul>
      ) : (
        <>
          <UnstyledButton
            className="NavMobile--menu-header"
            onClick={() =>
              setView(view === "accountSettings" ? "account" : "main")
            }
          >
            <Icon value="chevron_left" />
            {view === "resources"
              ? t("nav.resources", "Resources")
              : view === "language"
              ? t("nav.language", "Language")
              : view === "stats"
              ? t("nav.stats", "Stats")
              : view === "drops"
              ? t("nav.drops", "Drops")
              : view === "account"
              ? t("nav.accountLink", "Account")
              : t("nav.accountSettings", "Account settings")}
          </UnstyledButton>
          <ul className="NavMobile--menu">
            {view === "accountSettings" ? (
              <SettingsMenu variant="global" />
            ) : (
              navMenus[view]
            )}
            {view === "account" ? (
              <>
                <ViewNav view="accountSettings">
                  {t("nav.accountSettings", "Account settings")}
                </ViewNav>
                {activeAccount && (
                  <NavItem
                    key="logout"
                    onClick={async () => {
                      await logout()
                      showSuccessMessage(
                        t(
                          "nav.logoutSuccessMessage",
                          "You have been logged out successfully.",
                        ),
                      )
                    }}
                  >
                    {t("nav.logOutCtaLabel", "Log out")}
                  </NavItem>
                )}
              </>
            ) : null}
          </ul>
        </>
      )}
      <CenterAligned
        className="h-20 w-full shrink-0 border-t border-t-level-2 bg-elevation-2"
        style={{ zIndex: Z_INDEX.NAVBAR }} // TODO: Update to tailwind theme z-index
      >
        <SocialsItem isMobile />
      </CenterAligned>
    </DivContainer>
  )
}

const DivContainer = styled(Block)`
  background-color: ${props =>
    props.theme.colors.components.elevation.level2.background};
  display: flex;
  flex-direction: column;
  font-size: 16px;
  flex-grow: 1;
  white-space: nowrap;

  .NavMobile--menu {
    background: ${props =>
      props.theme.colors.components.elevation.level2.background};
    flex: 1;
    margin: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    .NavMobile--submenu-icon {
      margin-left: auto;
    }

    .NavMobile--toggle-container {
      justify-content: space-between;
      width: 100%;

      & * {
        transition: none;
      }
    }
  }

  .NavItem--icon {
    margin-right: 8px;
  }

  .NavMobile--menu-header {
    align-items: center;
    border-bottom: 1px solid
      ${props => props.theme.colors.components.border.level2};
    color: ${props => props.theme.colors.text.primary};
    display: flex;
    font-weight: 600;
    height: 80px;
    padding: 0 8px;
    flex-shrink: 0;
  }

  .UserMenu--tabs {
    position: initial;
  }

  .NavMobile--solana-pill {
    margin-top: 22px;
    padding: 0 20px;
    display: flex;
    align-items: center;
  }
`
