import React from "react"
import {
  ThemeProvider as NextThemesProvider,
  useTheme as useNextTheme,
} from "@opensea/next-themes"
import { useTheme as useUIKitTheme } from "@opensea/ui-kit"
import { ThemeProvider as SCThemeProvider } from "styled-components"
import { ThemeTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/ThemeTrackingContext.react"
import { Theme } from "../../styles/styled"
import THEMES from "../../styles/themes"

type Props = {
  theme: Theme | undefined
  children: React.ReactNode
  nonce?: string
}

const InnerThemeProvider = ({
  children,
  initialTheme,
}: {
  children: React.ReactNode
  initialTheme: Theme | undefined
}) => {
  const { resolvedTheme: resolvedNextTheme, theme: nextTheme } = useNextTheme()

  const resolvedTheme = resolvedNextTheme ?? initialTheme ?? "light"
  const theme = nextTheme ?? "light"

  return (
    <SCThemeProvider theme={THEMES[resolvedTheme]}>
      <ThemeTrackingContextProvider resolvedTheme={resolvedTheme} theme={theme}>
        {children}
      </ThemeTrackingContextProvider>
    </SCThemeProvider>
  )
}

export const ThemeProvider = ({
  children,
  theme: initialTheme,
  nonce,
}: Props) => {
  return (
    <NextThemesProvider nonce={nonce} theme={initialTheme}>
      <InnerThemeProvider initialTheme={initialTheme}>
        {children}
      </InnerThemeProvider>
    </NextThemesProvider>
  )
}

/**
 * @deprecated
 *
 * Using theme in JS directly is unsafe and not compatible with static page generation.
 * Use design system theming system instead.
 */
export const useTheme = useUIKitTheme

/**
 * @deprecated
 *
 * Using theme in JS directly is unsafe and not compatible with static page generation.
 * Use design system theming system instead.
 */
export const useThemeValues = () => {
  const { theme } = useUIKitTheme()
  return THEMES[theme]
}
