import { Address } from "viem"
import { createSiweMessage } from "viem/siwe"

type ResponseData = {
  nonce: string
}

type SiweMessage = {
  domain: string
  address: string
  statement: string
  uri: string
  version: string
  chainId: string
  nonce: string
  issuedAt: string
}

function parseSiweMessage(message: string): SiweMessage {
  const lines = message.split("\n")

  const domainMatch =
    /^(?<domain>[^ ]+) wants you to sign in with your Ethereum account:$/.exec(
      lines[0],
    )
  const addressMatch = /^(?<address>0x[0-9a-fA-F]{40})$/.exec(lines[1])
  const statementMatch = /^Sign in to (?<statement>.+)\.$/.exec(lines[3])
  const uriMatch = /^URI: (?<uri>.+)/.exec(lines[5])
  const versionMatch = /^Version: (?<version>\d+)/.exec(lines[6])
  const chainIdMatch = /^Chain ID: (?<chainId>\d+)/.exec(lines[7])
  const nonceMatch = /^Nonce: (?<nonce>.+)/.exec(lines[8])
  const issuedAtMatch = /^Issued At: (?<issuedAt>.+)/.exec(lines[9])

  return {
    domain: domainMatch ? domainMatch[1] : "",
    address: addressMatch ? addressMatch[0] : "",
    statement: statementMatch ? statementMatch[0] : "",
    uri: uriMatch ? uriMatch[1] : "",
    version: versionMatch ? versionMatch[1] : "",
    chainId: chainIdMatch ? chainIdMatch[1] : "",
    nonce: nonceMatch ? nonceMatch[1] : "",
    issuedAt: issuedAtMatch ? issuedAtMatch[1] : "",
  }
}

export class OSSiweAdapter {
  async getNonce() {
    const response = await fetch("/__api/auth/siwe/nonce", {
      method: "POST",
    })
    const responseJson: ResponseData = (await response.json()) as ResponseData
    return responseJson.nonce
  }

  async getWhitelisted(address: string) {
    const response = await fetch("/__api/auth/fut/verify", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ address }),
    })
    const responseJson = (await response.json()) as {
      success: boolean
    }
    return responseJson.success
  }

  async verify({ message, signature }: { message: string; signature: string }) {
    const jsonMessage = parseSiweMessage(message)

    const response = await fetch("/__api/auth/siwe/verify", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message: jsonMessage, signature }),
    })

    const session = (await response.json()) as {
      isLoggedIn: boolean
      user: { address: Address } | undefined
    }
    return session
  }

  public createMessage({ nonce, address }: { nonce: string; address: string }) {
    return createSiweMessage({
      version: "1",
      domain: window.location.host,
      uri: window.location.origin,
      address: address as Address,
      chainId: 1,
      nonce,
      statement: "Sign in to OpenSea.",
    })
  }

  async exchangeToken({ token }: { token: string }) {
    const response = await fetch("/__api/auth/exchange/privy", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    })

    const session = (await response.json()) as {
      isLoggedIn: boolean
      user: { address: Address } | undefined
    }
    return session
  }
}

export const osSiweAdapter = new OSSiweAdapter()
